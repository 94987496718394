import { Composite, CompositeItem } from '@floating-ui/react';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../../Actions/Button/Button';
import { Avatar } from '../../Avatar/Avatar';
import { DropdownMenuItem, DropdownMenuItemRenderProps } from '../../Dropdown/DropdownMenuItem';
import { useDropdownContext } from '../../Dropdown/provider';
import { MediaImage } from '../../Media';
import { HeaderUserDropdownProfilesProps } from './HeaderUserDropdownProfiles.types';

/**
 * A `HeaderUserDropdownProfiles` is a dropdown menu component for the `HeaderUserDropdown`. It is used to display
 * a list of profiles `<Avatar>` horizontally, with an optional edit button.
 *
 * @example
 *   <HeaderUserDropdownProfiles
 *     items={items} title="Profiles"
 *     labelEdit="Edit" onClickEdit={onClickEdit}
 *     labelAddProfile="Add Profile" onClickAddProfile={onClickAddProfile}
 *   />
 */
export function HeaderUserDropdownProfiles({
  items,
  title,
  labelEdit,
  labelAddProfile,
  onClickEdit,
  onClickAddProfile,
  showFadeIn,
  showPlaceholderAnim,
}: HeaderUserDropdownProfilesProps): JSX.Element {
  const { activeIndex } = useDropdownContext() || {};
  const [activeProfileIndex, setActiveProfileIndex] = useState(-1);
  const resetUlStyles = 'list-none m-dt-spacing-none p-dt-spacing-none';
  const avatarDefaultStyles = 'transition-transform duration-200 hover:outline-none';

  // Reset profile a11y index to -1 whenever the activeIndex changes
  // which allows us to always start on the first Avatar
  useEffect(() => {
    setActiveProfileIndex(-1);
  }, [activeIndex]);

  const avatarProfiles = useMemo(
    () =>
      items?.map(({ label, avatarImg, isCurrent = false, isKids = false, disabled = false, onClick }) => (
        <li role="none" key={`profiles-dropdownmenu-item-${label}`}>
          <CompositeItem
            render={(htmlProps) => (
              <Avatar
                className={classNames(avatarDefaultStyles, isCurrent ? 'w-88' : 'w-72 hover:scale-tv-focus')}
                label={label}
                aria-label={label}
                isKids={isKids}
                disabled={disabled}
                onClick={onClick}
                isButton={!!onClick}
                image={
                  avatarImg ? <MediaImage src={avatarImg.url} alt={avatarImg.alt} showFadeIn={showFadeIn} /> : undefined
                }
                variant={avatarImg?.url === '' ? 'account' : undefined}
                showPlaceholderAnim={showPlaceholderAnim}
                {...htmlProps}
              />
            )}
          />
        </li>
      )),
    [items, avatarDefaultStyles, showFadeIn, showPlaceholderAnim]
  );

  const buttonAddProfile = useMemo(
    () => (
      <li role="none">
        <CompositeItem
          render={(htmlProps) => (
            <Avatar
              className={classNames(avatarDefaultStyles, 'w-72 hover:scale-tv-focus')}
              label={labelAddProfile}
              aria-label={labelAddProfile}
              variant="add"
              onClick={onClickAddProfile}
              isButton={!!onClickAddProfile}
              showPlaceholderAnim={showPlaceholderAnim}
              {...htmlProps}
            />
          )}
        />
      </li>
    ),
    [labelAddProfile, onClickAddProfile, showPlaceholderAnim]
  );

  const renderProfileList = useCallback(
    ({ itemProps }: DropdownMenuItemRenderProps) => (
      <Composite
        orientation="horizontal"
        className={classNames(
          'inline-flex items-center w-full space-x-dt-spacing-200 px-dt-spacing-200 box-border',
          'no-scrollbar overflow-x-auto snap-x scroll-px-16', // Scroll
          'py-dt-spacing-25', // Padding for outline a11y and scrollbar
          'hover:outline-none'
        )}
        // Manage activeIndex and onNavigate externally
        activeIndex={activeProfileIndex}
        onNavigate={(index) => {
          // Focus first element when entering the profiles scrollable list
          if (activeProfileIndex === -1) {
            setActiveProfileIndex(0);
          } else {
            // Default behavior
            setActiveProfileIndex(index);
          }
        }}
        {...itemProps}
      >
        {avatarProfiles}
        {labelAddProfile && buttonAddProfile}
      </Composite>
    ),
    [avatarProfiles, buttonAddProfile, activeProfileIndex, labelAddProfile]
  );

  const renderEditProfileButton = useCallback(
    ({ itemProps }: DropdownMenuItemRenderProps) => (
      <Button variant="text" font="hind" width="full" {...itemProps}>
        {labelEdit}
      </Button>
    ),
    [labelEdit]
  );

  return (
    <ul role="menu" className={classNames(resetUlStyles)}>
      {title && <span>{title}</span>}
      <DropdownMenuItem label="profiles" renderComponent={renderProfileList} />

      {labelEdit && (
        <div className="px-dt-spacing-200">
          <DropdownMenuItem label={labelEdit} onClick={onClickEdit} renderComponent={renderEditProfileButton} />
        </div>
      )}
    </ul>
  );
}
