export enum R7LibGrabKeys {
  Up = 'Up',
  Down = 'Down',
  Enter = 'Enter',
  Left = 'Left',
  Right = 'Right',
  Back = 'Back',
  Rewind = 'Rewind',
  Forward = 'Forward',
}

export const POINTER_REFOCUS_DELAY = 150;
/**
 * Default selector for focusable elements, also used by Pointer:
 *  - (a[href], button:enabled): Default native focusable elements
 *  - [data-tv-focusable]: Custom focusable elements (MyCanal specific, e.g: Focusable but without any action on click)
 */
export const BINDER_DEFAULT_SELECTOR = "a[href], button:enabled:not([aria-disabled='true']), [data-tv-focusable]";
