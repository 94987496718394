import type { Immersive } from '../../../store/slices/immersive';
import type { Page } from '../../../store/slices/page';
import type { AppLocation, LocationState, LocationStateContext } from '../../../typings/routing';
import { MainOrSubOnClick } from '../types';

export type GetLinkerStateParameters = MainOrSubOnClick & {
  context: LocationStateContext;
  immersiveState: Immersive;
  location: AppLocation;
  pageState?: Page;
  replace?: boolean;
};

/** Compute a `LocationState` object from a set of props */
export function getLocationState({
  context,
  immersiveState,
  location,
  mainOnClick,
  pageState,
  replace,
  subOnClick,
}: GetLinkerStateParameters): LocationState {
  const {
    shouldSpreadImmersiveMainOnClick,
    shouldSpreadImmersiveSubOnClick,
    shouldSpreadPageMainOnClick,
    shouldSpreadPageSubOnClick,
  } = shouldSpreadOnClick(context, !!mainOnClick);

  const { shouldComputeImmersiveMainOnClick, shouldComputePageMainOnClick, shouldComputePageSubOnClick } =
    shouldComputeOnClick(context, !!mainOnClick);

  return {
    immersive: {
      ...(shouldSpreadImmersiveMainOnClick && { mainOnClick }),
      ...(shouldSpreadImmersiveSubOnClick && { subOnClick }),
      ...(shouldComputeImmersiveMainOnClick && { mainOnClick: immersiveState.mainOnClick }),
      ...(context === 'immersive' && { context, stackSize: incrementStackSize(location, replace) }),
    },
    page: {
      ...(shouldSpreadPageMainOnClick && { mainOnClick }),
      ...(shouldSpreadPageSubOnClick && { subOnClick }),
      ...(shouldComputePageMainOnClick && { mainOnClick: pageState?.mainOnClick }),
      ...(shouldComputePageSubOnClick && { subOnClick: pageState?.subOnClick }),
      ...(context !== 'immersive' && { context }),
    },
  };
}

export type ShouldSpreadOnClick = {
  shouldSpreadImmersiveMainOnClick: boolean;
  shouldSpreadImmersiveSubOnClick: boolean;
  shouldSpreadPageMainOnClick: boolean;
  shouldSpreadPageSubOnClick: boolean;
};

/** Helper to determine where the onClick should be spread in the linker's state */
export const shouldSpreadOnClick = (context: LocationStateContext, isMainOnClick: boolean): ShouldSpreadOnClick => {
  if (context === 'immersive') {
    return {
      shouldSpreadImmersiveMainOnClick: isMainOnClick,
      shouldSpreadImmersiveSubOnClick: !isMainOnClick,
      shouldSpreadPageMainOnClick: false,
      shouldSpreadPageSubOnClick: false,
    };
  }

  return {
    shouldSpreadImmersiveMainOnClick: false,
    shouldSpreadImmersiveSubOnClick: false,
    shouldSpreadPageMainOnClick: isMainOnClick,
    shouldSpreadPageSubOnClick: !isMainOnClick,
  };
};

export type ShouldComputeOnClick = {
  shouldComputeImmersiveMainOnClick: boolean;
  shouldComputePageMainOnClick: boolean;
  shouldComputePageSubOnClick: boolean;
};

/** Helper to determine when the onClick should be computed from the store */
export const shouldComputeOnClick = (context: LocationStateContext, isMainOnClick: boolean): ShouldComputeOnClick => {
  if (context === 'immersive') {
    return {
      shouldComputeImmersiveMainOnClick: !isMainOnClick,
      shouldComputePageMainOnClick: true,
      shouldComputePageSubOnClick: true,
    };
  }

  return {
    shouldComputeImmersiveMainOnClick: false,
    shouldComputePageMainOnClick: !isMainOnClick,
    shouldComputePageSubOnClick: false,
  };
};

/**
 * `stackSize` is used to keep track of how many immersive have been opened in a row
 *
 * ⚠️ Do not increment the stack size when a `replace` history action is performed
 */
function incrementStackSize(location: AppLocation, replace: boolean | undefined): number | undefined {
  if (replace) {
    return location.state?.immersive?.stackSize;
  }

  return (location.state?.immersive?.stackSize || 0) + 1;
}
