import { ApiV2AvatarV1Content } from '@dce-front/hodor-types/api/v2/avatars/v1/definitions';
import { ApiV2AvatarsV2Strate } from '@dce-front/hodor-types/api/v2/avatars/v2/definitions';
import { useSelector } from 'react-redux';
import { getFeatureToggleKidsProfiles } from '../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import {
  avatarSelector,
  isKidsProfileSettingsSelector,
  profileInputSelector,
  selectedProfileSelector,
} from '../../../store/slices/profilesModal-selectors';
import { allowSuppressionSelector } from '../../../store/slices/user-selectors';
import ProfileForm from './ProfileForm';

export type ProfileEditionProps = {
  avatars: ApiV2AvatarV1Content[] | ApiV2AvatarsV2Strate[];
};

function ProfileEdition({ avatars }: ProfileEditionProps): JSX.Element {
  const profile = useSelector(selectedProfileSelector);
  const allowSuppression = useSelector(allowSuppressionSelector(profile?.profileId));
  const avatarURL = useSelector(avatarSelector);
  const featKidsProfiles = useSelector(getFeatureToggleKidsProfiles);
  const isKidsProfileSettings = useSelector(isKidsProfileSettingsSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const profileInput = useSelector(profileInputSelector);

  return (
    <ProfileForm
      allowSuppression={allowSuppression}
      avatarURL={avatarURL}
      avatars={avatars}
      featKidsProfiles={featKidsProfiles}
      isKidsProfileSettings={isKidsProfileSettings}
      isTvDevice={isTvDevice}
      profile={profile}
      profileInput={profileInput}
    />
  );
}

export default ProfileEdition;
