import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import classNames from 'classnames';
import { forwardRef } from 'react';
import type { ModalProps } from './Modal.types';

export const DEFAULT_MODAL_TRANSITION_DURATION = 300;

/**
 * Renders an unstyled modal, with overlay, focus management,
 * dismiss handling, and transitions.
 */
export const Modal = forwardRef<HTMLDivElement, ModalProps>(function Modal(
  {
    isOpen,
    onOpenChange,
    children,
    role,
    dismissConfig,
    transitionConfig,
    portalRoot,
    portalContainerId,
    className,
    overlayClassName,
    'aria-labelledby': ariaLabelledby,
    'aria-describedby': ariaDescribedby,
    'aria-label': ariaLabel,
    'data-testid': dataTestId,
  }: ModalProps,
  ref
): React.ReactElement | null {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange,
  });
  const { isMounted, styles } = useTransitionStyles(context, {
    duration: DEFAULT_MODAL_TRANSITION_DURATION,
    ...transitionConfig,
  });
  const mergedRefs = useMergeRefs([refs.setFloating, ref]);
  const floatingRole = useRole(context, { role });
  const dismiss = useDismiss(context, dismissConfig);
  const { getFloatingProps } = useInteractions([floatingRole, dismiss]);

  if (!isMounted) return null;
  return (
    <FloatingPortal root={portalRoot} id={portalContainerId}>
      <FloatingOverlay
        className={classNames('flex items-center justify-center', overlayClassName)}
        style={styles}
        lockScroll
      >
        <FloatingFocusManager context={context}>
          <div
            className={className}
            ref={mergedRefs}
            aria-labelledby={ariaLabelledby}
            aria-describedby={ariaDescribedby}
            aria-modal="true"
            data-testid={dataTestId}
            aria-label={ariaLabel}
            {...getFloatingProps()}
          >
            {children}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
});
