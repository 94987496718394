import { MediaAspectRatio } from 'src/components/Media/Media.types';
import { MediaImageDimensions, MediaImageSource } from '../components/Media/MediaImage/MediaImage.types';
import { DiveDeviceMediaImage, DiveViewport } from '../types/Dive.types';

export enum ThumborQuality {
  Low = 30,
  Mid = 55,
  High = 80,
}

export enum DevicePixelRatio {
  Low = 1,
  Mid = 1.3,
  High = 2,
}

export type DeviceToImageUrl = {
  [key in DiveDeviceMediaImage]?: string;
};

export type ViewportToImageDimensions = {
  [key in DiveViewport]?: MediaImageDimensions & { ratio?: `${MediaAspectRatio}` };
};

export type DeviceToViewportToImageDimensions = {
  [key in DiveDeviceMediaImage]?: ViewportToImageDimensions;
};

export type ThumborSourcesDimensions = ViewportToImageDimensions | DeviceToViewportToImageDimensions;

export type ThumborSources = MediaImageSource[];

export type ThumborUrl = {
  /**
   * Thumbor-ready URL, with interpolated `quality` and `dimensions`.
   */
  url: string;
  /**
   * Thumbor-ready URL, with interpolated `quality` and Retina / HiDPI `dimensions`
   */
  urlRetina?: string;
};

export const THUMBOR_QUALITY_TO_DPR = {
  [ThumborQuality.Low]: DevicePixelRatio.Low,
  [ThumborQuality.Mid]: DevicePixelRatio.Mid,
  [ThumborQuality.High]: DevicePixelRatio.High,
} as const satisfies Record<ThumborQuality, DevicePixelRatio>;

export function isDeviceToViewportToImageDimensions(
  dimensions: ThumborSourcesDimensions
): dimensions is DeviceToViewportToImageDimensions {
  return typeof dimensions === 'object' && 'default' in dimensions;
}

export function isViewportToImageDimensions(
  dimensions: ThumborSourcesDimensions
): dimensions is ViewportToImageDimensions {
  return typeof dimensions === 'object' && 'XLarge' in dimensions;
}
