import { ButtonVariant, HeaderDropdownSeparator, HeaderUserDropdownButton } from '@canalplus/dive';
import { isSomeEnum } from '@canalplus/mycanal-commons';
import { IButtonStrate } from '@canalplus/mycanal-sdk';
import { Template } from '@canalplus/sdk-hodor';
import { Fragment } from 'react';
import { useLogout } from '../../../helpers/pass/useLogout';
import { useSigninRedirect } from '../../../helpers/pass/useSigninRedirect';
import Linker from '../../Linker/Linker';

type HeaderProfileButtonStrateProps = { isLastStrate?: boolean } & Pick<
  IButtonStrate,
  'title' | 'subtitle' | 'contents'
>;

function HeaderProfileButtonStrate({
  isLastStrate = false,
  title,
  subtitle,
  contents,
}: HeaderProfileButtonStrateProps): JSX.Element[] {
  const handleClick = useSigninRedirect();
  const logout = useLogout();

  return contents?.map(({ style, label = '', onClick }) => {
    const variant = isSomeEnum(ButtonVariant)(style) ? style : ButtonVariant.Primary;
    switch (onClick?.displayTemplate) {
      case Template.Authentication:
        return (
          <Fragment key={`headerProfile-strate-button-auth-${label}`}>
            <HeaderUserDropdownButton
              label={label}
              onClick={() => handleClick()}
              variant={variant}
              title={title}
              subtitle={subtitle}
            />
            {!isLastStrate && <HeaderDropdownSeparator />}
          </Fragment>
        );

      case Template.Logout:
        return (
          <HeaderUserDropdownButton
            key={`headerProfile-strate-button-logout-${label}`}
            label={label}
            onClick={logout}
            variant={variant}
          />
        );

      default:
        return (
          <Linker
            key={`headerProfile-strate-button-default-${label}`}
            data={{ mainOnClick: onClick }}
            renderWrapper={(linkerSettings) => (
              <HeaderUserDropdownButton label={label} variant={variant} {...linkerSettings} />
            )}
          />
        );
    }
  });
}

export default HeaderProfileButtonStrate;
