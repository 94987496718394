import {
  Avatar,
  HeaderDropdownSeparator,
  HeaderUserDropdown,
  HeaderUserDropdownProfiles,
  MediaImage,
} from '@canalplus/dive';
import { AvatarProps } from '@canalplus/dive/dist/types/components/Avatar/Avatar.types';
import { ApiV2NavigationItem } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import classNames from 'classnames/bind';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Linker from '../../../components/Linker/Linker';
import { getHeaderUserMenuProfiles } from '../../../helpers/navigation/navigation-helper';
import { LAYER_PAGE } from '../../../helpers/oneNavigation/layers';
import { rewriteObjectUrls } from '../../../helpers/proxyUrls/proxyUrls';
import I18n from '../../../lang';
import {
  getFeatureToggleKidsProfiles,
  isClientRenderSourceSelector,
} from '../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import {
  authenticatedSelector,
  hasProfilesLengthSelector,
  isKidsProfileSelector,
  profileImgSelector,
  profileNameSelector,
  profilesSelector,
  profilesWithoutKidsSelector,
} from '../../../store/slices/user-selectors';
import { useProfileSelector } from '../../Profiles/ProfileSelectorMyCanal/hooks/useProfileSelector';
import HeaderProfileContent from './HeaderProfileContent';
import styles from './HeaderProfileMyCanal.module.css';

const cx = classNames.bind(styles);

export type HeaderProfileMyCanalProps = {
  data?: ApiV2NavigationItem;
  isActive?: boolean;
};

function HeaderProfileMyCanal({ data, isActive }: HeaderProfileMyCanalProps): JSX.Element {
  const { onClick } = data || {};
  const { t } = I18n.useTranslation();
  const triggerAriaLabel = t('ProfileManager.profileIconAriaLabel');

  const isClient = useSelector(isClientRenderSourceSelector);
  const isKidsProfile = useSelector(isKidsProfileSelector);
  const isLoggedIn = useSelector(authenticatedSelector);
  const isProfileSelectorRendered = useSelector(hasProfilesLengthSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const triggerImageAlt = useSelector(profileNameSelector);
  const featKidsProfiles = useSelector(getFeatureToggleKidsProfiles);

  // To ensure that the URL of the userMenu avatar icon is properly proxied, we apply a guardrail as close as possible to the component
  const triggerImageUrl = rewriteObjectUrls(useSelector(profileImgSelector));
  const profiles = rewriteObjectUrls(useSelector(profilesSelector));
  const profilesWithoutKids = rewriteObjectUrls(useSelector(profilesWithoutKidsSelector));

  const profilesList = !featKidsProfiles ? profilesWithoutKids : profiles;
  const { handleProfileClick, handleEditButton, handleAddProfileClick, renderModals } = useProfileSelector(LAYER_PAGE);
  const profileItems = useMemo(() => {
    if (!profilesList || !isProfileSelectorRendered) return [];
    return getHeaderUserMenuProfiles(profilesList, handleProfileClick);
  }, [profilesList, isProfileSelectorRendered, handleProfileClick]);

  const avatarProps: AvatarProps = {
    'aria-label': triggerAriaLabel,
    isButton: !isTvDevice,
    isKids: isClient && isKidsProfile, // Currently, in SSR when don't know if the profile is a kids profile
    variant: isLoggedIn && triggerImageUrl ? 'default' : 'account',
    ...(isLoggedIn && triggerImageUrl && { image: <MediaImage src={triggerImageUrl} alt={triggerImageAlt} /> }),
  };

  return (
    <>
      <HeaderUserDropdown
        options={{ strategy: 'fixed' }}
        isSelected={isActive}
        closeIconAriaLabel={t('ProfileManager.closeModal')}
        menuStyles={cx('HeaderProfileMyCanal__menu')}
        backdropStyles={cx('HeaderProfileMyCanal__backdrop')}
        renderTrigger={({ triggerProps }) =>
          isTvDevice ? (
            <Linker data={{ mainOnClick: onClick }} aria-label={triggerAriaLabel} data-tv-focusmode="attribute">
              <Avatar {...avatarProps} {...triggerProps} />
            </Linker>
          ) : (
            <Avatar {...avatarProps} {...triggerProps} />
          )
        }
      >
        {isProfileSelectorRendered && (
          <>
            <HeaderUserDropdownProfiles
              items={profileItems}
              labelEdit={t('ProfileManager.manage')}
              onClickEdit={handleEditButton}
              labelAddProfile={t('ProfileManager.add')}
              onClickAddProfile={handleAddProfileClick}
            />
            <HeaderDropdownSeparator />
          </>
        )}
        <HeaderProfileContent />
      </HeaderUserDropdown>
      {isProfileSelectorRendered && renderModals()}
    </>
  );
}

export default HeaderProfileMyCanal;
