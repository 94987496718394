import type { IMeta } from '@canalplus/mycanal-sdk';
import { buildMeta } from '../../helpers/metaTags/buildMeta';
import { buildUpdatedMeta } from '../../helpers/metaTags/metaTags-helper';
import { setImmersiveMeta } from '../../store/slices/immersive';
import { isImmersiveSelector } from '../../store/slices/immersive-selectors';
import { setMeta } from '../../store/slices/meta';
import { zoneInfoSelector } from './application-selectors';

export type UpdateAndFormatMetaParameters = {
  meta: IMeta;
  displayTemplate?: string | null;
  displayName?: string | null;
  urlImage?: string;
};

export const updateAndFormatMeta =
  ({ meta, displayName, displayTemplate, urlImage }: UpdateAndFormatMetaParameters): Redux.ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const { offerLocation, appKey } = zoneInfoSelector(state);
    const isImmersive = isImmersiveSelector(state);

    const metaParams = buildMeta({
      displayName,
      displayTemplate,
      appKey,
      metaParams: meta,
      urlImage,
    });

    const updatedMeta = buildUpdatedMeta({ meta: metaParams, offerLocation, appKey });

    dispatch(
      isImmersive
        ? setImmersiveMeta(updatedMeta)
        : setMeta({
            meta: metaParams,
            offerLocation,
            appKey,
          })
    );
  };
