import { Dispatch, useContext } from 'react';
import { DidomiAction } from './didomiActions';
import { DidomiContext } from './didomiContext';
import { DidomiState } from './didomiTypes';

// A collection of selectors to avoid detail context destructuring in every component

const useDidomiContext = () => useContext(DidomiContext).state;

export const useDidomiDispatch = (): Dispatch<DidomiAction> => useContext(DidomiContext).dispatch;

export const useDidomiHasLoaded = (): boolean => useDidomiContext()?.hasLoaded;
export const useDidomiHasConsentChanged = (): boolean => useDidomiContext()?.hasConsentChanged;
export const useDidomiConsent = (): DidomiState['consent'] => useDidomiContext()?.consent;
export const useDidomiConsentString = (): string => useDidomiContext()?.consent?.consent_string;
export const useDidomiAdvertisingConsent = (): boolean => useDidomiContext()?.consent?.purposes?.advertising?.enabled;
export const useDidomiAnalyticsConsent = (): boolean => useDidomiContext()?.consent?.purposes?.analytics?.enabled;
export const useDidomiIsInIframe = (): boolean => useDidomiContext()?.isInIframe;
