import { Avatar } from '@canalplus/dive';
import { DIMENSIONS, PROFILE } from '@canalplus/mycanal-commons';
import { HorizontalList } from '@canalplus/mycanal-sharedcomponent';
import { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import classNames from 'classnames/bind';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { LAYER_WHOISWATCHING } from '../../../helpers/oneNavigation/layers';
import I18n from '../../../lang';
import { getFeatureToggleKidsProfiles } from '../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { profilesSelector } from '../../../store/slices/user-selectors';
import { useProfileSelector } from '../../Profiles/ProfileSelectorMyCanal/hooks/useProfileSelector';
import ThumborMediaImage from '../../ThumborMediaImage/ThumborMediaImage';
import styles from './ProfileSelector.css';

const filterProfileType = (profile: ApiV2Profile) => profile.type === PROFILE;
const filterProfileTypeAndKids = (profile: ApiV2Profile) => profile.type === PROFILE && !profile.isKidsProfile;
const cx = classNames.bind(styles);

function ProfileSelector({ handleClickAvatar }: { handleClickAvatar: () => void }): JSX.Element | null {
  const isTvDevice = useSelector(displayTVModeSelector);
  const profiles = useSelector(profilesSelector);
  const featKidsProfiles = useSelector(getFeatureToggleKidsProfiles);

  const { t } = I18n.useTranslation();

  // Need use a component state because we dont want the list updated when the list order changed
  const [profilesList, setProfilesList] = useState(
    featKidsProfiles ? profiles.filter(filterProfileType) : profiles.filter(filterProfileTypeAndKids)
  );

  const { handleProfileClickWithAuth, handleAddProfileClick, renderModals } = useProfileSelector(LAYER_WHOISWATCHING);

  // update profilesList when adding a new profile
  useEffect(() => {
    const profilesFiltered = featKidsProfiles
      ? profiles.filter(filterProfileType)
      : profiles.filter(filterProfileTypeAndKids);

    // Need update list only if we add a new profile
    if (profilesFiltered.length !== profilesList.length) {
      setProfilesList(profilesFiltered);
    }
  }, [profiles, featKidsProfiles, profilesList.length]);

  const profilesListWithAdd = useMemo(() => {
    const profileList = profilesList.map((profile) => {
      return (
        <li className={cx('profileSelector__item')} key={profile.profileId}>
          <Avatar
            label={profile.displayName}
            aria-label={profile.ariaLabel}
            image={
              <ThumborMediaImage
                url={profile.URLImage}
                urlMobile={profile.URLImage}
                dimensions={DIMENSIONS.PROFILE_AVATAR.extraLarge}
              />
            }
            isKids={profile.isKidsProfile}
            onClick={async (e: React.MouseEvent) => {
              await handleProfileClickWithAuth(profile, e);
              handleClickAvatar();
            }}
            className={styles.profileSelector__itemAvatar}
          />
        </li>
      );
    });

    profileList.push(
      <li className={styles.profileSelector__item} key="profile-add">
        <Avatar
          label={t('ProfileManager.add')}
          aria-label={t('ProfileManager.add')}
          variant="add"
          onClick={handleAddProfileClick}
        />
      </li>
    );

    return profileList;
  }, [t, profilesList, handleProfileClickWithAuth, handleAddProfileClick, handleClickAvatar]);

  return (
    <div className={cx('profileSelector', { 'profileSelector--fadeIn': true })}>
      {isTvDevice ? (
        <HorizontalList className={styles.profileSelector__list} showControls={false}>
          {profilesListWithAdd}
        </HorizontalList>
      ) : (
        <ul className={styles.profileSelector__list}>{profilesListWithAdd}</ul>
      )}
      {renderModals()}
    </div>
  );
}

export default ProfileSelector;
