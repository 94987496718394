import { Template } from '@canalplus/sdk-hodor';
import { rewriteObjectUrls } from '../helpers/proxyUrls/proxyUrls';
import { errorTemplate } from '../store/slices/error';
import { requestHodorService } from './helper';
import {
  CommonQueryHandlerResult,
  FormatterResult,
  GenericOutput,
  HodorResponseType,
  UniversalServiceProps,
} from './types';
import { universalFormatter } from './universalFormatter';

/**
 * ## universalService
 * Universal service which contains all logic of service for the useQueryTemplate
 * @param param0 It contains the url, fetchDetails and formatterProps
 * @example () => universalService<T>({ url, fetchDetails, formatterProps }) // In useQueryTemplate
 * @example ({ queryKey }) => universalService<T>({ url: queryKey, fetchDetails, formatterProps }) // In server.ts files
 */
export async function universalService<T extends GenericOutput>({
  fetchDetails,
  formatterProps,
  url,
  onError,
  routingContext = 'page',
}: UniversalServiceProps): Promise<FormatterResult<T>> {
  const { template, from } = fetchDetails;
  const isSubTemplate = from !== 'root';

  if (!url || typeof url !== 'string') {
    throw new Error('The url must be a string and not empty');
  }

  const urlToFetch = rewriteObjectUrls({ url }).url;

  const { dispatch } = formatterProps || {};
  const response = await requestHodorService<T & HodorResponseType>(urlToFetch, fetchDetails);

  // Be sure to have te correct type returned by the requestHodorService
  // May be wordy but we ensure it's the correct type
  if (
    dispatch &&
    response &&
    response.currentPage &&
    // Enforce type assertion due to large type declaration before
    (response?.currentPage as CommonQueryHandlerResult['currentPage'])?.displayTemplate === Template.Error &&
    // A sub-template (like a strate) returning an error,
    // should not overwrite the main template of a page or immersive
    !isSubTemplate
  ) {
    dispatch(
      errorTemplate({
        status: 404,
        immersive: routingContext === 'immersive',
        displayTemplate: Template.Error,
      })
    );
    if (onError) {
      onError();
    }
  }

  if (response === undefined) {
    throw new Error('No response provided');
  }

  return universalFormatter({
    hodorResponse: response,
    formatterProps: formatterProps || {},
    template,
  });
}
