import { updateUserConsent } from '@canalplus/sdk-pass';
import { usePassSdk } from '@canalplus/sdk-pass-react';
import { useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import Logger from '../../../helpers/logger/logger-helper';
import { getClientSideUserInfos } from '../../../helpers/user/getClientSideUserInfos';
import { shouldUsePassCache } from '../../../helpers/user/user-helper';
import { getFeatureToggleUserCentricConsent } from '../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { setTargetedAds } from '../../../store/slices/user';
import { authenticatedSelector } from '../../../store/slices/user-selectors';
import type { IState } from '../../../store/types/State-type';

export const useSetTargetedAds = (isTrackingScriptLoaded: boolean, enabled: boolean = true): void => {
  const state = useStore<IState>().getState();
  const dispatch = useAppDispatch();

  const updateUserConsentApi = usePassSdk(updateUserConsent);
  const isAuthenticated = useSelector(authenticatedSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const isUserCentricConsentMode = useSelector(getFeatureToggleUserCentricConsent);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (isTrackingScriptLoaded && !isTvDevice) {
      if (isUserCentricConsentMode) {
        document.addEventListener('userChangedConsent', (event: CustomEvent) => {
          const passCache = shouldUsePassCache();
          const { statsAllowed, adsAllowed, anonymousAllowed } = event?.detail || {};
          const settings = {
            analytics: statsAllowed,
            trackingPub: adsAllowed,
            anonymousTracking: anonymousAllowed,
          };

          // If the user is logged in and if user changed consent
          // Update user consent only if asked
          if (
            (isAuthenticated && window.passJSON?.askForConsent === true) ||
            (isAuthenticated &&
              (window.passJSON?.analytics !== settings.analytics ||
                window.passJSON?.trackingPub !== settings.trackingPub ||
                window.passJSON?.anonymousTracking !== settings.anonymousTracking))
          ) {
            try {
              updateUserConsentApi({
                passToken: window.passJSON?.passToken || '',
                settings,
              })
                .then(() => getClientSideUserInfos(state, { noCache: !passCache }))
                .catch((e) => {
                  Logger.error(`Pass::updateProfile Error while updating user profile: ${e}`);
                });
            } catch (e) {
              Logger.error(`Pass::updateProfile Error while updating user profile: ${e}`);
            }
          }

          // If the user is not logged in
          // and if user changed consent
          if (!isAuthenticated && (settings.anonymousTracking || settings.analytics || settings.trackingPub)) {
            getClientSideUserInfos(state, { noCache: !passCache, ...settings })
              .then()
              .catch((e) => {
                Logger.error(`Pass::getClientSideUserInfo Error while getting user info: ${e}`);
              });
          }

          const targetedAds = event?.detail?.adsAllowed ? '1' : '0';

          // Update the user.targetedAds in the store when GDPR consent is confirmed
          dispatch(setTargetedAds(targetedAds));
        });
      } else {
        window.waitForUserConsent?.(({ adsAllowed }) => {
          const targetedAds = adsAllowed ? '1' : '0';

          return dispatch(setTargetedAds(targetedAds));
        });
      }
    }
  }, [isTrackingScriptLoaded, enabled]); // eslint-disable-line react-hooks/exhaustive-deps
};
