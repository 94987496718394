import classNames from 'classnames';
import { cloneElement } from 'react';
import { useDiveContext } from '../../../context';
import { Media, MediaImage } from '../../Media';
import { HeaderLogoProps } from './HeaderLogo.types';

/**
 * A logo component to display in the header. It is essentially composed of:
 *
 * **Child component (itself)**:
 * - A simple `<img />` by passing the `src` prop and the `alt` prop.
 * - A custom component for the logo by passing the `customComponent` prop, like an SVG icon directly.
 *
 * **Wrapper:**
 * - `None` by default.
 * - Link `<a>` by passing the `href` prop.
 *
 * @example
 * <HeaderLogo
 *    alt="alt"
 *    src="https://[path].{svg|png|jpg|...}"
 * />
 */
export function HeaderLogo({
  className,
  alt,
  src,
  customComponent,
  href,
  'data-testid': dataTestId,
  showFadeIn,
  ...a11y
}: HeaderLogoProps): JSX.Element {
  const { isTv } = useDiveContext();

  const imgStyles = classNames(
    'inline-block',
    isTv ? 'h-48 tv-focus-self:outline-none' : 'h-28 dt-breakpoint-sm-start:h-32'
  );

  const component = customComponent ? (
    cloneElement(customComponent, { className: classNames(imgStyles, className) })
  ) : (
    <Media
      width="auto"
      className={classNames(isTv ? '!h-48' : '!h-28 dt-breakpoint-sm-start:!h-32', className)}
      image={<MediaImage width="auto" src={src} alt={alt} showFadeIn={showFadeIn} />}
      variant="ghost"
    />
  );

  if (href) {
    return (
      <a data-testid={dataTestId} href={href} className={classNames(imgStyles, className)} {...a11y}>
        {component}
      </a>
    );
  }

  return component;
}
