import classNames from 'classnames';
import { useState } from 'react';
import { useDiveContext } from '../../context';
import {
  LinkArrowRightSvg,
  StatusAlertSvg,
  StatusCrossSvg,
  StatusErrorSvg,
  StatusInfoDefaultSvg,
  StatusInfoSvg,
  StatusValidSvg,
} from '../../icons/icons';
import { DiveSVG } from '../../types/Dive.types';
import { Button } from '../Actions/Button/Button';
import { alertButtonCVA, alertCVA, alertContentCVA, alertIconCVA, alertTextCVA } from './Alert.cva';
import { AlertIconsAriaLabels, AlertProps, AlertStatus, AlertVariant } from './Alert.types';

export const STATUS_ICONS = {
  [AlertStatus.Error]: StatusErrorSvg,
  [AlertStatus.Hint]: StatusInfoDefaultSvg,
  [AlertStatus.Info]: StatusInfoSvg,
  [AlertStatus.Success]: StatusValidSvg,
  [AlertStatus.Warning]: StatusAlertSvg,
} as const satisfies Record<AlertStatus, DiveSVG>;
export const DEFAULT_ICONS_ARIA_LABELS = {
  [AlertStatus.Error]: 'Error',
  [AlertStatus.Hint]: 'Information',
  [AlertStatus.Info]: 'Information',
  [AlertStatus.Success]: 'Success',
  [AlertStatus.Warning]: 'Attention',
  close: 'Close',
} as const satisfies AlertIconsAriaLabels;

/**
 * The `Alert` component is designed to display information to the users,
 * presenting notifications and alerts related to various application statuses
 *
 * @example
 * <Alert
 *    status={AlertStatus.Error}
 *    message="This is an error message!"
 *    closable
 * />
 */
export function Alert({
  className,
  closable = false,
  message,
  title,
  status = AlertStatus.Hint,
  variant = AlertVariant.AlertBox,
  iconsAriaLabels = DEFAULT_ICONS_ARIA_LABELS,
  buttonLabel,
  buttonAriaLabel = buttonLabel,
  ignoreLabel = 'Ignore',
  onClick,
  onClose,
  'data-testid': dataTestId,
}: AlertProps): JSX.Element | null {
  const { isTv, device } = useDiveContext();
  const [isVisible, setIsVisible] = useState(true);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsVisible(false);
    onClick?.(event);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsVisible(false);
    onClose?.(event);
  };

  const isClosable = variant === AlertVariant.AlertBox && closable;

  const hasTitle = !!title;

  const hasButtonLabel = !!buttonLabel;

  const isDisplayInline = isTv || !hasTitle || !hasButtonLabel;

  const StatusIcon = STATUS_ICONS[status];

  if (!isVisible) return null;

  return (
    <div
      role={status === AlertStatus.Error || status === AlertStatus.Warning ? 'alert' : 'status'}
      data-testid={dataTestId}
      className={classNames(
        alertCVA({
          className,
          device,
          status,
          variant,
          hasTitle,
        })
      )}
    >
      <span
        className={classNames(
          alertIconCVA({
            device,
            variant,
            hasTitle,
          })
        )}
      >
        <StatusIcon role="img" aria-label={iconsAriaLabels[status]} />
      </span>
      <div className={alertContentCVA({ device, isDisplayInline })}>
        <div className={alertTextCVA({ variant, device, hasTitle, hasButtonLabel })}>
          {title && <span className="font-dt-font-weight-semibold">{title}</span>}
          {message && <span>{message}</span>}
        </div>
        {(buttonLabel || (isClosable && isTv)) && (
          <div className={alertButtonCVA({ device, isDisplayInline })}>
            {buttonLabel && !isTv && (
              <Button
                variant="text"
                font="hind"
                icon={
                  <LinkArrowRightSvg
                    role="img"
                    // ! TODO: Remove custom style after updating the Button component according to Figma
                    className="!basis-20 !h-20 dt-breakpoint-sm-start:!basis-22 dt-breakpoint-sm-start:!h-22 "
                  />
                }
                iconPosition="right"
                aria-label={buttonAriaLabel}
                onClick={handleClick}
                // ! TODO: Remove custom style after updating the Button component according to Figma
                className={classNames([
                  '[&>span]:pt-dt-spacing-none',
                  '[&>span]:text-dt-font-size-16 [&>span]:leading-dt-font-line-height-20',
                  '[&>span]:dt-breakpoint-sm-start:text-dt-font-size-18 [&>span]:dt-breakpoint-sm-start:leading-dt-font-line-height-22',
                ])}
              >
                {buttonLabel}
              </Button>
            )}
            {buttonLabel && isTv && (
              <Button font="hind" variant="tertiary" aria-label={buttonAriaLabel} onClick={handleClick}>
                {buttonLabel}
              </Button>
            )}
            {isClosable && isTv && (
              <Button font="hind" variant="tertiary" aria-label={iconsAriaLabels.close} onClick={handleClose}>
                {ignoreLabel}
              </Button>
            )}
          </div>
        )}
      </div>
      {isClosable && !isTv && (
        <Button
          variant="text"
          // ! TODO: Remove custom style after updating the Button component according to Figma
          icon={<StatusCrossSvg role="img" className="!basis-20 !h-20 !fill-dt-theme-icon-alert-box-icon" />}
          onClick={handleClose}
          aria-label={iconsAriaLabels.close}
          className="ml-auto shrink-0"
        ></Button>
      )}
    </div>
  );
}
