import { AppMenuSearchSvg, ButtonIcon } from '@canalplus/dive';
import { DATA_ATTR_HEADER_SEARCH_TRIGGER } from '@canalplus/mycanal-sharedcomponent';
import { NavigationInfoItem } from '@dce-front/hodor-types/modules/account_profile/definitions';
import { cloneElement } from 'react';
import { useSelector } from 'react-redux';
import { useActionCreators } from '../../../helpers/hooks/useActionCreators';
import I18n from '../../../lang';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { setSearchActive } from '../../../store/slices/search';
import Linker from '../../Linker/Linker';
import { SKIP_LINK_ID } from '../../SkipLink/constants';

type HeaderSearchButtonMyCanalProps = {
  isActive?: boolean;
  data?: NavigationInfoItem;
};

export function HeaderSearchButtonMyCanal({ isActive = false, data }: HeaderSearchButtonMyCanalProps): JSX.Element {
  const { onClick } = data || {};
  const { t } = I18n.useTranslation();
  const isTvDevice = useSelector(displayTVModeSelector);
  const { openSearch } = useActionCreators({ openSearch: setSearchActive });

  const searchComponent = (
    <ButtonIcon
      id={SKIP_LINK_ID.appHeaderSearch}
      aria-label={t('Search.activateSearchTriggerLabel')}
      icon={<AppMenuSearchSvg />}
      isSelected={isActive}
      variant="header"
      {...(!isTvDevice && {
        onClick: () => openSearch(),
        [DATA_ATTR_HEADER_SEARCH_TRIGGER]: true, // Do not delete: this is used to measure the DOM when header search initiates
      })}
    />
  );

  return isTvDevice ? (
    <Linker
      data={onClick ? { mainOnClick: onClick } : undefined}
      renderWrapper={(linkerSettings) => cloneElement(searchComponent, { onClick: linkerSettings.onClick })}
    />
  ) : (
    searchComponent
  );
}
