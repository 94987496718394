/**
 * Separator for the HeaderDropdown (NavDropdown or UserDropdown), used to separate
 * different sections of the dropdown.
 *  - A thin horizontal line of 1px height
 *  - Full width of the dropdown
 *  - Role "separator" for accessibility
 *
 * @returns {JSX.Element} The separator component
 * @example <HeaderDropdownSeparator />
 */
export function HeaderDropdownSeparator(): JSX.Element {
  return (
    <div
      role="separator"
      className="w-full border-solid border-dt-border-none border-b-dt-border-thin border-dt-theme-border-profile-card-divider"
    ></div>
  );
}
