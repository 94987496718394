import { Template } from '@canalplus/sdk-hodor';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ButtonShapes } from '../../constants/button';
import { StrateMode } from '../../constants/strates';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import Button from '../Button/Button';
import useLinker from './hooks/useLinker';
import { LinkerProps } from './types';

const LINKER = 'linker';

/**
 * Based on given `data`, generates one of the following:
 * - myCanal `Button` component
 * - react-router `Link` component
 * - `<a>`
 * - `<button>`
 * - or custom element with 'renderWrapper'
 *
 * with onClick, href and target values
 * @example
    <Linker
      data={{ mainOnClick: button.onClick }}
      onClick={onClose}
      title={button.displayName}
    >
      {button.displayName}
    </Linker>

    Or use render custom :

    <Linker
      data={{ mainOnClick: button.onClick }}
      onClick={onClose}
      renderWrapper={(linkerSettings: LinkerSettings, children?: ReactNode) => (
          linkerSettings.href ?
              <a href={linkerSettings.href} target={linkerSettings.target} onClick={linkerSettings.onClick}>
                {children}
              </a>
              :
              <button type="button" onClick={linkerSettings.onClick} className={styles.defaultTemplate_button}>
                {children}
              </button>
      )}
    >
      {button.displayName}
    </Linker>
*/
const Linker = forwardRef<HTMLElement, LinkerProps>(function Linker(props: LinkerProps, ref): JSX.Element {
  const {
    data,
    objKey = 'onClick',
    target = '_blank',
    id,
    onClick,
    replace,
    className,
    title,
    disabled = false,
    ariaLabel,
    renderWrapper,
    altImage,
    children,
    ...rest
  } = props;

  const isTvDevice = useSelector(displayTVModeSelector);
  const { getLinkerSettings } = useLinker();
  const linkerDataDisplayTemplate = data?.mainOnClick?.displayTemplate;
  const linkerSettings = getLinkerSettings({ data, objKey, target, onClick, replace });

  // case to render custom element with renderWrapper function
  if (renderWrapper) {
    return renderWrapper(linkerSettings, children);
  }

  // mostly used on detailV5 primary action button / episodeList
  if (linkerDataDisplayTemplate === Template.Player && objKey !== StrateMode.LiveTv) {
    return (
      <Button
        buttonRef={ref as React.RefObject<HTMLButtonElement>}
        id={id}
        shape={ButtonShapes.ROUND}
        className={className}
        text={title}
        isV5Style={isTvDevice}
        handler={linkerSettings.onClick}
        isDisabled={disabled}
        ariaLabel={ariaLabel}
      />
    );
  }

  // case react-router `Link` component
  if (linkerSettings.to) {
    return (
      <Link
        ref={ref as React.RefObject<HTMLAnchorElement>}
        className={className}
        onClick={linkerSettings.onClickWithoutRouting}
        to={linkerSettings.to}
        title={title}
        aria-label={ariaLabel || altImage || title}
        replace={replace}
        data-e2e={`${title ? title.replace(/\s/g, '-') : LINKER}`}
        id={id}
        aria-disabled={disabled}
        {...rest}
      >
        {children}
      </Link>
    );
  }

  // case HTML anchor
  if (linkerSettings.href && !linkerSettings.to) {
    return (
      <a
        ref={ref as React.RefObject<HTMLAnchorElement>}
        onClick={linkerSettings.onClick}
        className={className}
        target={linkerSettings.target}
        id={id}
        title={title}
        aria-label={ariaLabel || altImage || title}
        aria-disabled={disabled}
        {...(!disabled ? { href: linkerSettings.href } : {})}
        {...rest}
      >
        {children}
      </a>
    );
  }

  // case HTML button
  return (
    <button
      ref={ref as React.RefObject<HTMLButtonElement>}
      type="button"
      id={id && `${id}_onclick`}
      onClick={linkerSettings.onClick}
      className={className}
      aria-disabled={disabled}
      disabled={disabled}
    >
      {children}
    </button>
  );
});

export default Linker;
