import classNames from 'classnames';
import { useDiveContext } from '../../../context';
import { fadeInOnLoad, handleRef } from './MediaImage.helpers';
import { MediaImageProps } from './MediaImage.types';

/**
 * Displays an image based on given `src` and optional `sources`.
 * 
  * When `showFadeIn` is set to `true`:
 * - A default CSS animation is enabled, which fades the image in over `0.3s`.
 * - If JavaScript is enabled on the client rendering the image, the fade-in animation is deferred until the image's `onLoad` event fires. In the case of an image with multiple source, only the first `onLoad` triggers a fade-in.
 * 
 * When `sources` are provided, the image is wrapped in a `<picture>` element that enables [responsive images art direction](https://developer.mozilla.org/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images#art_direction). To prioritize performance, `sources` are ignored by default on TV devices, but can be enabled by setting `shouldPrioritizePerformance` to `false`.
 * 
 * @example
 * <MediaImage
 *   alt="Avatar: The Way of Water"
 *   src="http://avatar.jpeg"
 *   sources={[
 *     {
         media: '(max-width: 800px)',
         srcset: [
           'http://avatar_800px.jpeg',
           'http://avatar_800px.jpeg 2x'
         ]
       }
     ]}
   />
 */
export default function MediaImage({
  id,
  src,
  alt = '',
  sources,
  onLoad,
  onTransitionEnd,
  loading = 'lazy',
  height = '100%',
  width = '100%',
  className,
  shouldPrioritizePerformance = true,
  showFadeIn = false,
  'data-testid': dataTestId,
  ...a11y
}: MediaImageProps): JSX.Element {
  const { isTv } = useDiveContext();

  const img = (
    <img
      id={id}
      src={src}
      alt={alt}
      ref={showFadeIn ? handleRef : undefined}
      onLoad={showFadeIn ? fadeInOnLoad(onLoad) : onLoad}
      onTransitionEnd={onTransitionEnd}
      className={classNames(
        'box-border',
        isTv
          ? 'text-dt-theme-tv-text-media-block-media-block-title'
          : 'text-dt-theme-text-media-block-media-block-title',
        { 'animate-fade-in': showFadeIn },
        className
      )}
      loading={loading}
      height={height}
      width={width}
      style={{ minWidth: width, height }}
      {...a11y}
    />
  );

  if (!sources || (isTv && shouldPrioritizePerformance)) {
    return img;
  }

  return (
    <picture data-testid={dataTestId} {...a11y}>
      {sources?.map(({ media, srcset, 'data-testid': dataTestIdSource }) => (
        <source key={media} media={media} srcSet={srcset?.join(', ')} data-testid={dataTestIdSource} />
      ))}
      {img}
    </picture>
  );
}
