import { AlertBanner, AlertStatus } from '@canalplus/dive';
import { castToEnum } from '@canalplus/mycanal-commons';
import { Binder, useStore } from '@canalplus/one-navigation';
import { MouseEvent, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeColor } from '../../constants/themeColor';
import { TrackingEventName } from '../../constants/tracking';
import { saveNotificationNextDisplayDate } from '../../helpers/notification/notification-helper';
import { LAYER_DIALOG_MODAL, LAYER_PAGE } from '../../helpers/oneNavigation/layers';
import { NotificationTrackingEventName, sendTrackingHitOnNotification } from '../../helpers/tracking/tracking-helper';
import I18n from '../../lang';
import { isStartupNotificationOpen, offerLocationSelector } from '../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import { pageURLSelector } from '../../store/slices/page-selectors';
import { accountIdSelector, isKidsProfileSelector } from '../../store/slices/user-selectors';
import { useAlerting } from '../Application/hooks/useAlerting';
import useLinker from '../Linker/hooks/useLinker';
import SlideDown from '../SlideDown/SlideDown';
import Alert from './Alert';
import styles from './AlertNotification.css';

export default function AlertNotificationContainer(): ReactNode {
  const { t } = I18n.useTranslation();
  const accountId = useSelector(accountIdSelector);
  const isKids = useSelector(isKidsProfileSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const localeInfo = useSelector(offerLocationSelector);
  const pageURL = useSelector(pageURLSelector) || '';
  const isSlideShowOpen = useSelector(isStartupNotificationOpen);
  const { alert, currentPage, tracking } = useAlerting() || {};
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(true);
  const storeOneNavigation = useStore();

  const sendNotificationTracking = useCallback(
    (eventName: NotificationTrackingEventName) => {
      if (tracking?.dataLayer) {
        const { alert_segment_id: segmentId, alert_state: state } = tracking.dataLayer;
        sendTrackingHitOnNotification({
          eventName,
          isKids,
          themeColor: ThemeColor.Dark,
          alertType: state,
          segType: segmentId,
          version: $_BUILD_APP_VERSION,
          pageURL,
          offerLocation: localeInfo,
        });
      }
    },
    [isKids, localeInfo, pageURL, tracking]
  );

  useEffect(() => {
    if (alert && isAlertOpen) {
      sendNotificationTracking(TrackingEventName.ViewAlert);
    }
  }, [alert, isAlertOpen, sendNotificationTracking]);

  useEffect(() => {
    if (!isTvDevice || !alert) {
      return;
    }
    if (isAlertOpen) {
      storeOneNavigation.setActiveLayer(LAYER_DIALOG_MODAL);
      storeOneNavigation.focusDefault();
    } else {
      storeOneNavigation.setActiveLayer(LAYER_PAGE);
      storeOneNavigation.focusDefault();
    }
  }, [storeOneNavigation, isTvDevice, alert, isAlertOpen]);

  const handleClose = useCallback(() => {
    if (alert && currentPage && accountId !== undefined) {
      setIsAlertOpen(false);
      saveNotificationNextDisplayDate({
        displayTemplate: currentPage.displayTemplate,
        contentID: alert.contentID,
        accountId,
        daysBetweenTriggers: alert?.triggers?.daysBetweenTriggers,
      });
      sendNotificationTracking(TrackingEventName.CloseAlert);
    }
  }, [alert, currentPage, accountId, sendNotificationTracking]);

  const { getLinkerSettings } = useLinker();
  const { onClick } = useMemo(
    () => getLinkerSettings({ data: { mainOnClick: alert?.button?.onClick }, target: alert?.button?.onClick?.target }),
    [alert, getLinkerSettings]
  );

  const handleCTAClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement> | SyntheticEvent) => {
      handleClose();
      onClick?.(e);
    },
    [onClick, handleClose]
  );

  if (!alert || isKids || isSlideShowOpen) {
    return null;
  }

  const renderWrapperBinder = ({
    children,
    className,
  }: {
    children: React.ReactNode;
    className?: string;
  }): JSX.Element => (
    <Binder className={className} layer={LAYER_DIALOG_MODAL} forceFocusOnMount>
      {children}
    </Binder>
  );
  return !isTvDevice ? (
    <SlideDown isOpen={isAlertOpen}>
      <Alert
        status={castToEnum(AlertStatus)(alert.state)}
        title={alert.title}
        message={alert.description || ''}
        closable={alert.isClosable}
        onClose={handleClose}
        onClick={handleCTAClick}
        buttonLabel={alert.button?.label}
        buttonAriaLabel={alert.button?.ariaLabel}
      />
    </SlideDown>
  ) : (
    <AlertBanner
      overlayClassName={styles.alertBannerOverlay}
      childrenWrapper={renderWrapperBinder}
      isOpen={isAlertOpen}
      status={castToEnum(AlertStatus)(alert.state)}
      title={alert.title}
      description={alert.description || ''}
      primaryButtonAriaLabel={alert.button?.ariaLabel}
      primaryButtonLabel={alert.button?.label || ''}
      secondaryButtonLabel={t('Alert.ignore')}
      secondaryButtonAriaLabel={t('Alert.ignore')}
      onPrimaryClick={handleCTAClick}
      onSecondaryClick={handleClose}
    />
  );
}
