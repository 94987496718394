import { usePrevious } from '@canalplus/mycanal-util-react';
import { IAPICredentialsRaw } from '@canalplus/oneplayer-types';
import Cookies from 'js-cookie';
import { R7PlayerStoppedData } from 'r7extlib';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CookieKey } from '../../constants/cookie';
import { PlayerPlatform } from '../../constants/playerPlatforms';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { useAppDispatch } from '../../helpers/hooks/useAppDispatch';
import { getEncodedPassId } from '../../helpers/user/user-helper';
import { isR7Available } from '../../helpers/window/window-helper';
import {
  fullLocaleSelector,
  getFeatureToggleAds,
  getFeatureToggleBlueTim,
  getFeatureToggleDidomi,
  getFeatureTogglePositionSeconds,
  isPlayerScriptLoadedSelector,
  offerLocationSelector,
  offerZoneSelector,
  tokenCMSSelector,
} from '../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import { immersiveTrackingSelector } from '../../store/slices/immersive-selectors';
import { pageDisplayNameSelector } from '../../store/slices/page-selectors';
import { type PlayerInstance, killPlayer } from '../../store/slices/player';
import { playerInstanceSelector, playerSettingsSelector } from '../../store/slices/player-selectors';
import { effectiveTrackingContextSelector } from '../../store/slices/tracking-selectors';
import {
  abTestingPopulationSelector,
  adTokenSelector,
  isKidsProfileSelector,
  microEligibilitySelector,
  passTokenSelector,
  profileIdSelector,
  targetedAdsSelector,
} from '../../store/slices/user-selectors';
import { useDidomiConsentString } from '../DidomiProvider/didomiHooks';
import styles from './Player.css';
import { createPlayerInstance } from './helpers/createPlayerInstance';
import { removePlayer } from './helpers/removePlayer';
import { CommonProps } from './helpers/types';
import { updatePlayerSettings } from './helpers/updatePlayerSettings';

/**
 * HTML5Player
 *
 * This container instantiates OnePlayer when prop `settings` changes.
 * The `DisplayMode` (fullscreen, windowed, etc.) should be managed
 * at the level of `PlayerContainer`'s parent.
 */
export function HTML5Player(): JSX.Element {
  const dispatch = useAppDispatch();

  const playerContainerElementRef = useRef<HTMLDivElement | null>(null);

  const isTvDevice = useSelector(displayTVModeSelector);
  const adToken = useSelector(adTokenSelector);
  const enableAd = useSelector(getFeatureToggleAds);
  const isFeatureTogglePositionSeconds = useSelector(getFeatureTogglePositionSeconds);
  const isPlayerScriptLoaded = useSelector(isPlayerScriptLoadedSelector);
  const offerLocation = useSelector(offerLocationSelector);
  const offerZone = useSelector(offerZoneSelector);
  const playerInstance = useSelector(playerInstanceSelector);
  const settings = useSelector(playerSettingsSelector);
  const targetedAds = useSelector(targetedAdsSelector);
  const locale = useSelector(fullLocaleSelector);
  const pageDisplayName = useSelector(pageDisplayNameSelector);
  const abTestingPopulation = useSelector(abTestingPopulationSelector);
  const profileId = useSelector(profileIdSelector);
  const microEligibility = useSelector(microEligibilitySelector);
  const passToken = useSelector(passTokenSelector);
  const tokenCMS = useSelector(tokenCMSSelector);
  const immersiveTracking = useSelector(immersiveTrackingSelector);
  const immersiveTrackingContext = useSelector(effectiveTrackingContextSelector) || {};
  const isKids = useSelector(isKidsProfileSelector);
  const isBlueTim = useSelector(getFeatureToggleBlueTim);
  const consentString = useDidomiConsentString();
  const { DIDOMI } = getPublicConfig();
  const isFeatDidomi = useSelector(getFeatureToggleDidomi);

  const playerRef = useRef<PlayerInstance | undefined>(
    playerInstance && Object.keys(playerInstance).length ? playerInstance : undefined
  );

  const [deviceId, , trackingKeyId] = Cookies.get(CookieKey.DeviceId)?.split(':') || [];

  const credentials: IAPICredentialsRaw = useMemo(
    () => ({
      abTestPopulation: String(abTestingPopulation || ''),
      appSessionId: Cookies.get(CookieKey.SessionId),
      deviceId,
      microEligibility,
      passId: getEncodedPassId(),
      passToken,
      profileId: String(profileId) || '',
      tokenCMS,
      trackingKeyId,
    }),
    [abTestingPopulation, deviceId, microEligibility, passToken, profileId, tokenCMS, trackingKeyId]
  );

  /**
   * When kill player, store currentTime and idView in redux to be used to calculate last progression in detailV5
   */
  const onKillPlayer = useCallback(
    (data?: R7PlayerStoppedData) => {
      const player = playerRef.current;
      let currentTime: number | undefined;
      let idView: string | undefined;

      // case TV, get timecode
      if (isTvDevice && data && data.timecode) {
        currentTime = data.timecode;
        idView = data.idView;
      }

      // case Web
      // Need check player.player because if player.destroy() is called before, player.player is null and getCurrentTime() or getContentInfo() throws exceptions
      // For live content we dont need store currentTime and idView
      if (!isTvDevice && player && player.player && settings?.platform !== PlayerPlatform.Live) {
        currentTime = Number(player.getCurrentTime()) * 1000; // convert to ms

        const contentInfo = player.getContentInfo();
        idView = contentInfo.idView as string;
      }

      dispatch(killPlayer({ lastCurrentTime: currentTime, lastWatchedEpisodeStreamId: idView }));
    },
    [dispatch, playerRef, settings, isTvDevice]
  );

  /**
   * Update or Insert the player instance
   */
  const upsertPlayer = useCallback((): void => {
    const playerContainerElement = playerContainerElementRef.current;
    const commonProps: CommonProps = {
      adToken,
      credentials,
      dispatch,
      enableAd,
      isBlueTim,
      isTvDevice,
      isFeatDidomi,
      locale,
      offerLocation,
      offerZone,
      playerContainerElement,
      settings,
      targetedAds,
      consentString,
      onKillPlayer,
    };

    if (playerRef.current && !isTvDevice) {
      // If the component is mounted with `settings` prop and playerInstance already exists,
      // just update the player settings
      updatePlayerSettings({
        ...commonProps,
        playerInstance: playerRef.current,
      });
    } else {
      // If we have the player script and settings but no instance, we have to create the player instance
      const newPlayerInstance = createPlayerInstance({
        ...commonProps,
        isFeatureTogglePositionSeconds,
        tracking: immersiveTracking,
        trackingContext: immersiveTrackingContext,
        isKids,
      });

      if (newPlayerInstance) {
        playerRef.current = newPlayerInstance;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adToken,
    credentials,
    enableAd,
    isFeatureTogglePositionSeconds,
    isTvDevice,
    locale,
    offerLocation,
    offerZone,
    playerRef,
    settings,
    targetedAds,
  ]);

  useEffect(() => {
    if (settings && isPlayerScriptLoaded) {
      upsertPlayer();
    }

    // on TV, and on player exit :
    // - kill player in order to be able to launch it again without having to leave the immersive
    // - refetch data to get the up-to-date perso data (progress, remaining time etc...)
    if (isTvDevice && isR7Available()) {
      window.R7.addStreamListener('player:stopped', onKillPlayer);
    }

    return () => {
      if (isTvDevice && isR7Available()) {
        window.R7.removeStreamListener('player:stopped');
      }

      removePlayer({ dispatch });
      // Handle the case of external player destruction (e.g closing immersive)
      if (settings) {
        onKillPlayer();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlayerScriptLoaded, isTvDevice, settings, onKillPlayer]);

  const previousProps = usePrevious({ settings, targetedAds, consentString });

  useEffect(() => {
    if (previousProps !== undefined) {
      // TV mode case when switching between live channels
      if (isTvDevice && previousProps.settings && settings && previousProps.settings?.content !== settings?.content) {
        upsertPlayer();
      }

      // If `settings` exist in new version of props, create the player or update its settings
      if (!previousProps.settings && settings && isPlayerScriptLoaded) {
        upsertPlayer();
      }

      // If `settings` prop doesn't exist in new version of props, remove the player
      if (previousProps.settings && !settings) {
        // Remove player instance
        removePlayer({ dispatch });
      }

      // Update GDPR targetedAds and consentString in the Player if it changes
      if (!isFeatDidomi && !DIDOMI?.ACTIVE && previousProps.targetedAds !== targetedAds && playerRef.current) {
        playerRef.current.setAdData({ gdpr: 1, targetedAds });
      }

      if (isFeatDidomi && DIDOMI?.ACTIVE && previousProps.consentString !== consentString && playerRef.current) {
        playerRef.current.setAdData({ gdpr: 1, targetedAds, ...(consentString && { consentString }) });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, targetedAds, consentString]);

  const bindPlayerContainer = (element: HTMLDivElement): void => {
    playerContainerElementRef.current = element;
  };

  // tabIndex is useful when opening player in ssr detail page
  return (
    <div
      ref={bindPlayerContainer}
      role="dialog"
      tabIndex={-1}
      aria-modal="true"
      aria-label={pageDisplayName}
      className={styles.player}
    />
  );
}
