import { requestPage } from '@canalplus/sdk-hodor';
import { ApiV2UserProfile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../helpers/hooks/useAppDispatch';
import Logger from '../../../../helpers/logger/logger-helper';
import { rewriteObjectUrls } from '../../../../helpers/proxyUrls/proxyUrls';
import {
  getAllProfiles,
  removeAllProfiles,
  setCurrentProfile,
  setProfiles,
  setProfilesUpdateDate,
} from '../../../../services/profiles/profileStorageService';
import {
  appKeySelector,
  getFeatureToggleProfile,
  getFeatureToggleWhoIsWatching,
  hodorSdkConfigSelector,
  shouldDisplayWhoIsWatchingSelector,
} from '../../../../store/slices/application-selectors';
import { userListProfile, userListProfiles } from '../../../../store/slices/user';
import { profilesUpdateDateSelector } from '../../../../store/slices/user-selectors';
import { getListProfileUrl } from './helpers/getListProfileUrl';

export type UseUpdateListProfiles = {
  updateListProfiles: () => Promise<void>;
};

/**
 * Hook to update list profiles after create/modify a profile
 * It check if the profilesUpdateDate has changed and call service getListProfiles to get the new list profiles,
 * and store it in redux.
 * @returns {UseUpdateListProfiles} return method 'updateListProfiles' to update profiles after create/modify a profile
 */
export const useUpdateListProfiles = (): UseUpdateListProfiles => {
  const dispatch = useAppDispatch();

  const hodorSdkConfig = useSelector(hodorSdkConfigSelector);
  const featProfile = useSelector(getFeatureToggleProfile);
  const featureToggleWhoIsWatching = useSelector(getFeatureToggleWhoIsWatching);
  const shouldDisplayWhoIsWatching = useSelector(shouldDisplayWhoIsWatchingSelector);
  const profilesUpdateDate = useSelector(profilesUpdateDateSelector);
  const appKey = useSelector(appKeySelector);

  const updateListProfiles = async () => {
    try {
      if (!featProfile && (!featureToggleWhoIsWatching || !shouldDisplayWhoIsWatching)) {
        return;
      }

      const { profilesUpdateDate: profilesUpdateDateStored } = getAllProfiles();

      if (profilesUpdateDate === profilesUpdateDateStored && hodorSdkConfig) {
        let { contents: newProfiles } = (await requestPage(hodorSdkConfig, {
          url: getListProfileUrl(appKey),
          parameters: [
            { id: 'accept-language', in: 'header' },
            { id: 'tokenPass', in: 'header' },
            { id: 'xx-profile-id', in: 'header' },
          ],
        })) as ApiV2UserProfile;

        newProfiles = rewriteObjectUrls(newProfiles);

        setProfiles(newProfiles);
        setProfilesUpdateDate(profilesUpdateDate);

        dispatch(userListProfiles(newProfiles));

        if (newProfiles[0]) {
          setCurrentProfile(newProfiles[0]);
          dispatch(userListProfile(newProfiles[0]));
        }
      }
    } catch (error) {
      removeAllProfiles();
      Logger.error(`useUpdateListProfiles::updateListProfiles() - Error occurs during updateListProfiles ${error}`);
    }
  };

  return { updateListProfiles };
};
