import classNames from 'classnames';
import { useDiveContext } from '../../../context';
import { MaterialCloseSvg } from '../../../icons/icons';
import { ButtonIcon } from '../../Actions/ButtonIcon/ButtonIcon';
import { Dropdown } from '../../Dropdown/Dropdown';
import { HeaderUserDropdownMenu } from '../HeaderUserDropdownMenu/HeaderUserDropdownMenu';
import { HeaderUserDropdownProps } from './HeaderUserDropdown.types';

// Header height 64px => Trigger height 32px => 64 - 32 = 32px / 2 = 16px
// To align the dropdown with the bottom of the header
const DROPDOWN_OFFSET_OPTIONS = { offset: { mainAxis: 16 } };

/**
 * A `HeaderUserDropdown` is a user component for the `Header`. It is used to display the user's
 * profile information and actions using dropdown mechanics. It contains an avatar and a dropdown
 * menu. It is not displayed exactly the same on different devices:
 *  - On Web, it is displayed as a dropdown (`Avatar` as trigger + `Dropdown Menu` when hovered)
 *  - On TV, it is displayed as a button only (`Avatar` as button), without dropdown
 *
 * @example
 * <HeaderUserDropdown
 *   renderTrigger={({ triggerProps }) => (<Avatar {...triggerProps} />)}
 *   closeIconAriaLabel="Mobile close button aria-label"
 * >
 *   <HeaderUserDropdownProfiles {...} />
 *   <HeaderDropdownSeparator {...} />
 *   <HeaderUserDropdownList {...} />
 *   <HeaderUserDropdownLogout {...} />
 * </HeaderUserDropdown>
 */
export function HeaderUserDropdown({
  id,
  isSelected = false,
  closeIconAriaLabel,
  renderTrigger,
  children,
  onOpenChange,
  options = DROPDOWN_OFFSET_OPTIONS,
  menuStyles,
  backdropStyles,
  'data-testid': dataTestId,
}: HeaderUserDropdownProps): JSX.Element {
  const { isTv } = useDiveContext();
  const triggerStyles = classNames('flex items-center cursor-pointer rounded-full transition-transform duration-200', {
    'size-32 bg-transparent border-dt-border-none p-dt-spacing-none': !isTv,
    'size-54': isTv,
    'ring-op50-4': isTv && isSelected, // ! TODO: To be removed if Avatar uses ButtonIcon in the future
  });

  if (isTv) {
    return renderTrigger({ triggerProps: { id, className: triggerStyles } });
  }

  return (
    <Dropdown
      data-testid={dataTestId}
      options={options}
      onOpenChange={onOpenChange}
      renderDismissButton={({ onClose }) => (
        <ButtonIcon
          icon={<MaterialCloseSvg />}
          className="dt-breakpoint-sm-start:hidden scale-125 mr-dt-spacing-100 mb-dt-spacing-150 float-end"
          aria-label={closeIconAriaLabel}
          onClick={onClose}
        />
      )}
      renderTrigger={({ triggerProps }) =>
        renderTrigger({ triggerProps: { ...triggerProps, id, className: triggerStyles } })
      }
      menuStyles={classNames('outline-none z-10 size-full dt-breakpoint-sm-start:size-auto', menuStyles)}
      backdropStyles={classNames(
        'bg-dt-theme-background-profile-card-profile-card dt-breakpoint-sm-start:hidden',
        backdropStyles
      )}
    >
      <HeaderUserDropdownMenu>{children}</HeaderUserDropdownMenu>
    </Dropdown>
  );
}
