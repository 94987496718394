import { useCallback, useEffect, useState } from 'react';
import { ThemeColor } from '../../constants/themeColor';
import { sendTrackingHitOnNotification } from '../../helpers/tracking/tracking-helper';
import type { UserNotification } from '../../store/slices/user-type';
import Notification from '../Notification/Notification';
import SlideDown from '../SlideDown/SlideDown';
import { storeNotification } from './storeNotification';

export type NotificationDisplayerProps = {
  isKids: boolean;
  localeInfo?: string;
  notification?: UserNotification;
  pageURL: string;
};

function NotificationDisplayer({
  isKids,
  localeInfo,
  notification,
  pageURL,
}: NotificationDisplayerProps): JSX.Element | null {
  const [isNotificationBarShown, setIsNotificationBarShown] = useState<boolean>(false);

  const handleCloseNotification = useCallback(() => {
    if (notification) {
      storeNotification({ ...notification, hidden: true });
      setIsNotificationBarShown(false);
    }
  }, [notification]);

  useEffect(() => {
    const { hidden = false, segType } = notification || {};

    if (notification && !isNotificationBarShown && !hidden) {
      setIsNotificationBarShown(true);
      sendTrackingHitOnNotification({
        isKids,
        themeColor: ThemeColor.Dark,
        alertType: segType,
        segType,
        version: $_BUILD_APP_VERSION,
        pageURL,
        offerLocation: localeInfo,
      });
    }
  }, [isNotificationBarShown, notification]); // eslint-disable-line react-hooks/exhaustive-deps

  return notification ? (
    <SlideDown isOpen={isNotificationBarShown}>
      <Notification {...notification} closeAction={handleCloseNotification} />
    </SlideDown>
  ) : null;
}

export default NotificationDisplayer;
