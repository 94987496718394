import classNames from 'classnames';
import { useId } from 'react';
import { Button } from '../Actions/Button/Button';
import { DEFAULT_ICONS_ARIA_LABELS, STATUS_ICONS } from '../Alert/Alert';
import { AlertStatus } from '../Alert/Alert.types';
import { DEFAULT_MODAL_TRANSITION_DURATION, Modal } from '../Modal/Modal';
import { AlertBannerProps } from './AlertBanner.types';

const DefaultChildrenWrapper = ({ children }: { children: React.ReactNode }) => children;

/**
 * The alert banner is a modal in TV that draws the users attention to an important information,
 * with alert status, action buttons and requires a response.
 */
export function AlertBanner({
  status = AlertStatus.Hint,
  title,
  description,
  primaryButtonLabel,
  secondaryButtonLabel,
  onPrimaryClick,
  onSecondaryClick,
  isOpen,
  onOpenChange,
  shouldPrioritizePerformance = false,
  childrenWrapper: ChildrenWrapper,
  portalRoot,
  portalContainerId,
  iconsAriaLabels = DEFAULT_ICONS_ARIA_LABELS,
  primaryButtonAriaLabel,
  secondaryButtonAriaLabel,
  className,
  overlayClassName,
  'data-testid': dataTestId,
  'aria-label': ariaLabel,
}: AlertBannerProps): JSX.Element | null {
  const titleId = useId();
  const descriptionId = useId();
  const StatusIcon = STATUS_ICONS[status];
  const Wrapper = ChildrenWrapper || DefaultChildrenWrapper;
  return (
    <Modal
      role="alertdialog"
      aria-describedby={descriptionId}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className={classNames(
        'w-[51rem] font-dt-font-family-system',
        'bg-dt-theme-tv-surface-alert-banner-alert-banner rounded-dt-radius-xlarge',
        { 'flex flex-col items-center p-dt-spacing-400 box-border space-y-dt-spacing-600': !ChildrenWrapper },
        className
      )}
      data-testid={dataTestId}
      dismissConfig={{ enabled: false }}
      transitionConfig={{ duration: shouldPrioritizePerformance ? 0 : DEFAULT_MODAL_TRANSITION_DURATION }}
      overlayClassName={classNames('bg-dt-theme-tv-background-alert-banner-alert-banner', overlayClassName)}
      portalRoot={portalRoot}
      portalContainerId={portalContainerId}
      {...(title ? { 'aria-labelledby': titleId } : { 'aria-label': ariaLabel })}
    >
      <Wrapper
        className={classNames({
          'flex flex-col items-center p-dt-spacing-400 box-border space-y-dt-spacing-600': !!ChildrenWrapper,
        })}
      >
        <div className="flex flex-col items-center space-y-dt-spacing-300">
          <span className="[&>svg]:size-56 block">
            <StatusIcon role="img" aria-label={iconsAriaLabels[status]} />
          </span>
          <div className="text-center">
            {title && (
              <h2
                id={titleId}
                className={classNames([
                  'm-dt-spacing-none', // reset default style
                  'text-dt-font-size-30 font-dt-font-weight-semibold leading-dt-font-line-height-36',
                  'text-dt-theme-tv-text-alert-banner-alert-banner',
                ])}
              >
                {title}
              </h2>
            )}
            <p
              id={descriptionId}
              className={classNames([
                'm-dt-spacing-none', // reset default style
                'text-dt-font-size-30 font-dt-font-weight-regular leading-dt-font-line-height-36',
                'text-dt-theme-tv-text-alert-banner-alert-banner',
              ])}
            >
              {description}
            </p>
          </div>
        </div>
        <div className="flex flex-col space-y-dt-spacing-200">
          <Button onClick={onPrimaryClick} width="fixed" variant="primary" aria-label={primaryButtonAriaLabel}>
            {primaryButtonLabel}
          </Button>
          {secondaryButtonLabel && (
            <Button onClick={onSecondaryClick} width="fixed" variant="primary" aria-label={secondaryButtonAriaLabel}>
              {secondaryButtonLabel}
            </Button>
          )}
        </div>
      </Wrapper>
    </Modal>
  );
}
