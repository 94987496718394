import { History } from 'history';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { hodorSdkConfigSelector } from '../../../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../../../store/slices/displayMode-selectors';
import { immersiveSelector } from '../../../../../store/slices/immersive-selectors';
import { pageSelector } from '../../../../../store/slices/page-selectors';
import type { AppLocation } from '../../../../../typings/routing';
import { isOnClickDifferFromState } from '../useGetPageContent/useGetPageContent';

/*
 * The page scroll must go back to the top each time the user navigates to a new page (excluding TV which manages its own scrolling handler).
 * This excludes navigation when closing an immersive (because the main background page remains the same) or during browser backward/forward
 * navigation because the app benefits from history scrollRestoration.
 */

export default function usePageScrollTop(history: History, location: AppLocation): void {
  const isTvDevice = useSelector(displayTVModeSelector);
  const hodorSdkConfig = useSelector(hodorSdkConfigSelector);
  const page = useSelector(pageSelector);
  const immersive = useSelector(immersiveSelector);

  useEffect(() => {
    // We check if hodorSdkConfig is loaded before performing the scroll to top on SSR to avoid
    // some weird behavior on refreshing an immersive page. On chrome the scroll restoration doesn't work fine
    // and move the position of screen after the loading of hodorSdkConfig
    if (isTvDevice || !hodorSdkConfig) {
      return;
    }

    const fromSSR = location.state?.fromSSR;
    const onClickImmersive = location.state?.immersive?.mainOnClick;
    const onClickPage = location.state?.page?.mainOnClick;

    if (fromSSR) {
      // To avoid an edge effect due to the combination of scrollRestoration and immersives,
      // the app scrolls to the top for each first rendering (SSR).
      window.scrollTo(0, 0);
      return;
    }

    const isBrowserNavigation = history.action === 'POP';
    if (isBrowserNavigation) {
      return;
    }

    const willPageTemplateBeUpdated =
      !!onClickPage && isOnClickDifferFromState(onClickPage, page) && history.action !== 'REPLACE';

    const willImmersiveTemplateBeUpdated =
      !!onClickImmersive && isOnClickDifferFromState(onClickImmersive, immersive.mainOnClick);

    if (willPageTemplateBeUpdated && !willImmersiveTemplateBeUpdated) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, !!hodorSdkConfig]); // eslint-disable-line react-hooks/exhaustive-deps
}
