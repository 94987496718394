import { HodorSdkConfig, getHodorApiParametersFromBranSdkOnClickParameters, requestPage } from '@canalplus/sdk-hodor';
import { ApiV2Authenticate } from '@dce-front/hodor-types';
import loggerHelper from '../../helpers/logger/logger-helper';
import { NavigationItemType, type MockApiV2UserMenuLanguages } from '../../typings/hodor';

export async function getUserMenuLanguages(
  sdkConfig: HodorSdkConfig,
  userMenu: ApiV2Authenticate['userMenu'] = []
): Promise<MockApiV2UserMenuLanguages | undefined> {
  const { onClick, parameters } = userMenu.find((entry) => entry.type === NavigationItemType.Text) || {};
  const { URLPage, displayTemplate } = onClick || {};

  if (URLPage && displayTemplate === 'languageSelector') {
    const hodorApiParameters = getHodorApiParametersFromBranSdkOnClickParameters(parameters);

    try {
      const userMenuLanguages = (await requestPage(sdkConfig, {
        url: URLPage,
        ...(hodorApiParameters.length && { parameters: hodorApiParameters }),
      })) as MockApiV2UserMenuLanguages;

      return userMenuLanguages;
    } catch (error) {
      loggerHelper.error(`getUserMenuLanguages() - Error occurs during getUserMenuLanguages ${error}`);
    }
  }

  return undefined;
}
