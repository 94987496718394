import { useFloatingTree, useListItem, useMergeRefs } from '@floating-ui/react';
import { AriaAttributes, HTMLAttributes, forwardRef } from 'react';
import { DiveProps } from '../../types/Dive.types';
import { useDropdownContext } from './provider';

export type DropdownMenuItemRenderProps = {
  /**
   * Whether the item is a11y focused or not
   */
  isActive?: boolean;
  /**
   * Whether the item is nested or not
   */
  isNested?: boolean;
  /**
   * Floating-UI A11y Props to spread on the item in order to make it focusable
   */
  itemProps?: Record<string, unknown>;
};

type Ref = HTMLDivElement & HTMLButtonElement & HTMLAnchorElement;

export type DropdownMenuItemProps = {
  /**
   * Label of the item (also necessary for A11y typeahead focus management)
   */
  label: string;
  /**
   * Whether the item is disabled or not (non-focusable through keyboard/typeahead)
   */
  disabled?: boolean;
  /**
   * Custom render function to render the item instead of default button
   */
  renderComponent?: (props: DropdownMenuItemRenderProps) => JSX.Element;
} & HTMLAttributes<Ref> &
  AriaAttributes &
  DiveProps;

/**
 * DropdownMenuItem is the item of the Dropdown component. It is essentially used inside a dropdown menu,
 * and provides accessibility and keyboard navigation management (floating-ui).
 * It does so by managing: the focus, the keyboard navigation, the type ahead and dismiss event.
 * By default, it renders a button element, but it can be customized through the renderComponent prop.
 * It uses ARIA roving tabindex for focus management (tabIndex 0 if focused, and -1 if not), managed by
 * floating-ui's `useListNavigation()`.
 *
 * @returns A focusable item with necessary A11y props and floating-ui data-attributes.
 * @example
 *   <DropdownMenuItem label="My dropdown content" />
 *   <DropdownMenuItem label="My dropdown content" disabled />
 *   <DropdownMenuItem label="My dropdown content" renderComponent={({ isActive, isNested, itemProps }) => <MyCustomComponent {...itemProps} />} />
 */
export const DropdownMenuItem = forwardRef<Ref, DropdownMenuItemProps>(
  ({ label, className, renderComponent, disabled = false, ...props }, forwardedRef) => {
    const { isOpen, activeIndex, isNested, getItemProps } = useDropdownContext() || {};

    const { ref, index } = useListItem({ label: disabled ? null : label });
    const mergedRef = useMergeRefs([ref, forwardedRef]);
    const tree = useFloatingTree();
    const isActive = isOpen && index === activeIndex;

    /**
     * Roving tabindex A11y management (recommended by WAI-ARIA)
     * @see https://www.w3.org/WAI/ARIA/apg/practices/keyboard-interface/#kbd_roving_tabindex
     *
     * Normally, a roving index is set between 0 and -1, but we use 0 and undefined to allow native focus management as well.
     */
    const tabIndex = isActive ? 0 : undefined;

    // @floating-ui necessary props
    const itemProps = {
      role: 'menuitem',
      tabIndex,
      ref: mergedRef,
      ...props,
      ...getItemProps({
        onClick(event: React.MouseEvent<Ref>) {
          props?.onClick?.(event);
          tree?.events.emit('click');
        },
        onFocus(event: React.FocusEvent<Ref>) {
          props?.onFocus?.(event);
        },
      }),
    };

    return renderComponent ? (
      renderComponent({ isActive, isNested, itemProps })
    ) : (
      <button type="button" className={className} disabled={disabled} {...itemProps}>
        {label}
      </button>
    );
  }
);

DropdownMenuItem.displayName = 'DropdownMenuItem';

DropdownMenuItem.defaultProps = {
  disabled: false,
  renderComponent: undefined,
};
