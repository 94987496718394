import { Header } from '@canalplus/dive';
import { HEADER_ID, HeaderTheme, useHeaderTheme } from '@canalplus/mycanal-sharedcomponent';
import { Binder } from '@canalplus/one-navigation';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { MIDDLEWARE_HEADER } from '../../helpers/oneNavigation/middleware';
import {
  isHeaderActiveOnTVSelector,
  isHeaderDarkSelector,
  isHeaderTransparentSelector,
} from '../../selectors/header-selectors';
import { featDisplayHeaderMenuSelector } from '../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import { isSearchActiveSelector } from '../../store/slices/search-selectors';
import HeaderLogoMyCanal from './HeaderLogoMyCanal/HeaderLogoMyCanal';
import styles from './HeaderMyCanal.module.css';
import HeaderNavigationMyCanal from './HeaderNavigationMyCanal/HeaderNavigationMyCanal';
import HeaderUserMenuMyCanal from './HeaderUserMenuMyCanal/HeaderUserMenuMyCanal';
import { HeaderWithSearchLayoutMyCanalConnected } from './HeaderWithSearchLayoutMyCanal/HeaderWithSearchLayoutMyCanalConnected';

const cx = classNames.bind(styles);

function HeaderMyCanal(): JSX.Element {
  const isTvDevice = useSelector(displayTVModeSelector);
  const isHeaderDark = useSelector(isHeaderDarkSelector);
  const isHeaderTransparent = useSelector(isHeaderTransparentSelector);
  const isSearchActive = useSelector(isSearchActiveSelector);
  const isHeaderActiveOnTV = useSelector(isHeaderActiveOnTVSelector);
  const featDisplayHeaderMenu = useSelector(featDisplayHeaderMenuSelector);

  const headerTheme = useHeaderTheme(isHeaderDark, isHeaderTransparent, isSearchActive);
  const isTransparent = headerTheme === HeaderTheme.TRANSPARENT;

  return isTvDevice ? (
    <Binder
      data-template="header"
      enabled={featDisplayHeaderMenu && isHeaderActiveOnTV}
      middleware={MIDDLEWARE_HEADER}
      data-nosnippet
    >
      <Header
        logo={<HeaderLogoMyCanal isTransparent={isTransparent} />}
        primaryNav={<HeaderNavigationMyCanal isTransparent={isTransparent} />}
        secondaryNav={<HeaderUserMenuMyCanal />}
        isTransparent={isTransparent}
        isFixed={false} // Header is not fixed on TV
      />
    </Binder>
  ) : (
    <HeaderWithSearchLayoutMyCanalConnected>
      <div data-nosnippet>
        <Header
          id={HEADER_ID}
          logo={<HeaderLogoMyCanal isTransparent={isTransparent} />}
          primaryNav={<HeaderNavigationMyCanal isTransparent={isTransparent} />}
          secondaryNav={<HeaderUserMenuMyCanal />}
          isTransparent={isTransparent}
          isFixed={false} // Position fixed is managed by StickyContainer parent wrapper
          className={cx({ 'HeaderMyCanal--searchOpen': isSearchActive })}
        />
      </div>
    </HeaderWithSearchLayoutMyCanalConnected>
  );
}

export default HeaderMyCanal;
