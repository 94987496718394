import classNames from 'classnames';
import { useId } from 'react';
import { ToggleSwitchProps } from './ToggleSwitch.types';

/**
 * The ToggleSwitch represents a physical switch that allows users to
 * turn a setting On or Off and trigger an immediate `onClick` action by:
 * - Clicking on the ToggleSwitch itself
 * - Pressing the `Enter` or `Space` key after having focusing on the ToggleSwitch
 *
 * Requires either a `label` or an `aria-label` to ensure accessibility.
 *
 * @example
 * // Without label, including an aria-label for accessibility:
 * <ToggleSwitch isToggled aria-label="Accessible label" />
 *
 * // With a label and a sub-label:
 * <ToggleSwitch label="Label" subLabel="Optional sub-label" onClick={handleAction} />
 */
export function ToggleSwitch({
  label,
  labelledById: labelledByIdProps,
  subLabel,
  isToggled = false,
  disabled = false,
  onClick,
  className,
  'aria-label': ariaLabel,
  'data-testid': dataTestId,
}: ToggleSwitchProps): JSX.Element {
  const labelId = useId();
  const labelledById = !label
    ? // Without `label`, ToggleSwitch's purely visual <button> element relies on `aria-label` for accessibility
      labelledByIdProps
    : // An ID is generated to identify the <p> element receiving `label` as labelling the <button>
      labelId;
  const labelStyles =
    'font-dt-font-family-system text-dt-font-size-16 leading-dt-font-line-height-20 font-dt-font-weight-regular';

  return (
    <div className={classNames('max-w-fit flex items-center', className)} data-testid={dataTestId}>
      <button
        type="button"
        role="switch"
        aria-checked={isToggled}
        aria-label={ariaLabel}
        aria-labelledby={labelledById}
        disabled={disabled}
        onClick={onClick}
        className={classNames(
          'relative min-w-43 h-22 rounded-dt-radius-circle border-none',
          'transition-colors duration-300',
          isToggled
            ? 'bg-dt-theme-background-toggle-switch-toggle-switch-on'
            : 'bg-dt-theme-background-toggle-switch-toggle-switch-off',
          disabled ? 'cursor-not-allowed opacity-dt-opacity-50' : 'cursor-pointer'
        )}
      >
        <span
          className={classNames(
            'absolute size-18 rounded-dt-radius-circle top-2 left-2 bg-dt-theme-surface-toggle-switch-toggle',
            'transition-transform ease-out',
            'origin-center rotate-0 scale-x-100 scale-y-100 skew-x-0 skew-y-0 translate-y-0',
            isToggled ? 'translate-x-20' : 'translate-x-0'
          )}
          aria-hidden
        />
      </button>
      {label && (
        <p
          id={labelledById}
          className={classNames(
            labelStyles,
            'my-dt-spacing-none ml-dt-spacing-200',
            disabled
              ? 'text-dt-theme-text-toggle-switch-toggle-switch-label-disabled'
              : 'text-dt-theme-text-toggle-switch-toggle-switch-label'
          )}
        >
          {label}
          {subLabel && (
            <span
              className={classNames(
                labelStyles,
                'ml-dt-spacing-100',
                disabled
                  ? 'text-dt-theme-text-toggle-switch-toggle-switch-sublabel-disabled'
                  : 'text-dt-theme-text-toggle-switch-toggle-switch-sublabel'
              )}
            >
              {subLabel}
            </span>
          )}
        </p>
      )}
    </div>
  );
}
