import { useEffect } from 'react';

const isSSR = typeof window === 'undefined';

type UseScrollIntoViewProps = {
  /**
   * Container that wraps the item.
   * @example <ul ref={containerRef}>...</nav>
   */
  containerRef: React.RefObject<HTMLElement>;
  /**
   * Index of the item to scroll into view.
   */
  index?: number;
  /**
   * Enable or disable the hook.
   */
  enabled?: boolean;
  /**
   * Throttle interval for the resize event.
   * @default 250
   */
  throttleInterval?: number;
};

export const useScrollIntoView = ({
  containerRef,
  index,
  enabled = true,
  throttleInterval = 250,
}: UseScrollIntoViewProps): void => {
  useEffect(() => {
    if (!enabled || isSSR || !containerRef.current || index === undefined || index < 0) {
      return;
    }

    const containerRect = containerRef.current.getBoundingClientRect();
    const children = Array.from(containerRef.current.children);

    if (index >= children.length) {
      return;
    }

    const itemAtIndex = children[index] as HTMLElement;
    const scrollToPosition = itemAtIndex.offsetLeft + itemAtIndex.clientWidth / 2 - containerRect.width / 2;
    containerRef.current.scrollTo({ left: scrollToPosition, behavior: 'smooth' });
  }, [containerRef, index, enabled, throttleInterval]);
};
