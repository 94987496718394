import { cva } from 'class-variance-authority';
import { AlertStatus } from './Alert.types';

export const alertCVA = cva(['flex items-start w-auto rounded-dt-radius-small'], {
  variants: {
    variant: {
      alertBox: undefined,
      inputFieldAlertStandalone: undefined,
      inputFieldAlertAttached: undefined,
    },
    device: {
      web: undefined,
      tv: undefined,
    },
    status: {
      error: undefined,
      hint: undefined,
      info: undefined,
      success: undefined,
      warning: undefined,
    },
    hasTitle: {
      true: undefined,
      false: undefined,
    },
  },
  compoundVariants: [
    {
      variant: 'inputFieldAlertAttached',
      device: 'tv',
      className: [
        'rounded-tl-dt-radius-none rounded-tr-dt-radius-none',
        'rounded-bl-dt-radius-medium rounded-br-dt-radius-medium',
        'px-dt-spacing-300',
        'pt-dt-spacing-350',
        'pb-dt-spacing-250',
      ],
    },
    {
      variant: 'inputFieldAlertStandalone',
      device: 'web',
      className: 'rounded-dt-radius-small',
    },
    {
      variant: 'inputFieldAlertStandalone',
      device: 'tv',
      className: 'rounded-dt-radius-medium p-dt-spacing-400',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      device: 'web',
      className: 'p-dt-spacing-100',
    },
    {
      variant: 'inputFieldAlertAttached',
      device: 'web',
      className: [
        'rounded-tl-dt-radius-none rounded-tr-dt-radius-none',
        'rounded-bl-dt-radius-small rounded-br-dt-radius-small',
      ],
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      status: 'error',
      device: 'web',
      className: 'bg-dt-theme-surface-input-field-alert-input-field-alert-error',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      status: 'hint',
      device: 'web',
      className: 'bg-dt-theme-surface-input-field-alert-input-field-alert',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      status: 'info',
      device: 'web',
      className: 'bg-dt-theme-surface-input-field-alert-input-field-alert-info',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      status: 'success',
      device: 'web',
      className: 'bg-dt-theme-surface-input-field-alert-input-field-alert-success',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      status: 'warning',
      device: 'web',
      className: 'bg-dt-theme-surface-input-field-alert-input-field-alert-warning',
    },
    {
      variant: 'alertBox',
      device: 'web',
      hasTitle: true,
      className: 'p-dt-spacing-200',
    },
    {
      variant: 'alertBox',
      device: 'web',
      hasTitle: false,
      className: 'px-dt-spacing-200 py-15',
    },
    {
      variant: 'alertBox',
      device: 'tv',
      className: 'p-dt-spacing-400',
    },
    {
      variant: 'alertBox',
      status: 'error',
      device: 'web',
      className: 'bg-dt-theme-surface-alert-box-alert-box-error',
    },
    {
      variant: 'alertBox',
      status: 'hint',
      device: 'web',
      className: 'bg-dt-theme-surface-alert-box-alert-box',
    },
    {
      variant: 'alertBox',
      status: 'info',
      device: 'web',
      className: 'bg-dt-theme-surface-alert-box-alert-box-info',
    },
    {
      variant: 'alertBox',
      status: 'success',
      device: 'web',
      className: 'bg-dt-theme-surface-alert-box-alert-box-success',
    },
    {
      variant: 'alertBox',
      status: 'warning',
      device: 'web',
      className: 'bg-dt-theme-surface-alert-box-alert-box-warning',
    },
    {
      status: Object.values(AlertStatus),
      device: 'tv',
      className: 'bg-dt-theme-tv-surface-alert-alert',
    },
  ],
});

export const alertContentCVA = cva('flex self-start my-auto', {
  variants: {
    device: {
      web: 'ml-dt-spacing-150',
      tv: 'w-full justify-between ml-dt-spacing-300',
    },
    isDisplayInline: {
      true: undefined,
      false: 'flex-col',
    },
  },
  compoundVariants: [
    {
      device: 'tv',
      isDisplayInline: true,
      className: 'flex-row',
    },
    {
      device: 'web',
      isDisplayInline: true,
      className: 'flex-col dt-breakpoint-sm-start:flex-row',
    },
  ],
});

export const alertButtonCVA = cva('flex', {
  variants: {
    device: {
      web: undefined,
      tv: 'space-x-dt-spacing-300',
    },
    isDisplayInline: {
      true: undefined,
      false: 'mt-dt-spacing-100',
    },
  },
  compoundVariants: [
    {
      device: 'tv',
      isDisplayInline: true,
      className: 'self-center',
    },
    {
      device: 'web',
      isDisplayInline: true,
      className: [
        'dt-breakpoint-sm-start:self-center',
        'ml-dt-spacing-none dt-breakpoint-sm-start:ml-dt-spacing-50',
        'mt-dt-spacing-100 dt-breakpoint-sm-start:mt-dt-spacing-none',
      ],
    },
  ],
});

export const alertIconCVA = cva('flex', {
  variants: {
    variant: {
      alertBox: undefined,
      inputFieldAlertStandalone: undefined,
      inputFieldAlertAttached: undefined,
    },
    device: {
      web: undefined,
      tv: undefined,
    },
    hasTitle: {
      true: undefined,
      false: undefined,
    },
  },
  compoundVariants: [
    {
      variant: 'alertBox',
      device: 'web',
      className: 'self-start',
    },
    {
      variant: 'alertBox',
      device: 'web',
      className: '[&>svg]:size-20',
    },
    {
      variant: 'alertBox',
      device: 'tv',
      className: '[&>svg]:size-36',
    },
    {
      variant: 'alertBox',
      device: 'tv',
      hasTitle: false,
      className: 'self-center',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      device: 'web',
      className: '[&>svg]:size-16 self-start',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      device: 'tv',
      className: '[&>svg]:size-40',
    },
  ],
});

export const alertTextCVA = cva(
  'flex flex-col justify-start self-start my-auto font-dt-font-family-system font-dt-font-weight-regular',
  {
    variants: {
      variant: {
        alertBox: '[&>svg]:size-20',
        inputFieldAlertStandalone: '[&>svg]:size-16',
        inputFieldAlertAttached: '[&>svg]:size-16',
      },
      device: {
        web: ['text-dt-font-size-14 leading-dt-font-line-height-18'],
        tv: 'text-dt-font-size-30 leading-dt-font-line-height-36',
      },
      hasTitle: {
        true: undefined,
        false: undefined,
      },
      hasButtonLabel: {
        true: undefined,
        false: undefined,
      },
    },
    compoundVariants: [
      {
        variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
        device: 'web',
        className: ['text-dt-theme-text-input-field-alert-input-field-alert'],
      },
      {
        variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
        device: 'tv',
        className: 'text-dt-theme-tv-text-input-field-alert-input-field-alert',
      },
      {
        variant: 'alertBox',
        device: 'web',
        className: [
          'text-dt-theme-text-alert-box-alert-box',
          'dt-breakpoint-sm-start:text-dt-font-size-16 dt-breakpoint-sm-start:leading-dt-font-line-height-20',
        ],
      },
      {
        variant: 'alertBox',
        device: 'web',
        hasTitle: false,
        hasButtonLabel: false,
        className: 'pt-dt-spacing-25',
      },
      {
        variant: 'alertBox',
        device: 'tv',
        className: 'text-dt-theme-tv-text-alert-box-alert-box',
      },
    ],
  }
);
