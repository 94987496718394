import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setCookieWhoIsWatchingSession } from '../../../helpers/whoIsWatching/cookieHelper';
import {
  getFeatureToggleWhoIsWatching,
  getWhoIsWatchingSettingsSelector,
  shouldDisplayWhoIsWatchingSelector,
  userSelector,
} from '../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';

export const INTERVAL_SET_COOKIE_SESSION = 60000; // 1 minute in ms

type UseWhoIsWatching = {
  /**
   * Indicate if the screen WhoIsWatching is displayed or not
   */
  showWhoIsWatching: boolean;
};

/**
 * Hook that indicate if WhoIsWatching screen at startup have to be displayed.
 * It manages too the set of the session cookie WhoIsWatching just before leave the app.
 * WhoIsWatching screen is displayed if:
 * - User is authenticated
 * - The feature WhoIsWatching is enabled (featureToggleWhoIsWatching in settings)
 * - WhoIsWatching is not disabled in preference user
 * - WhoIsWatching is not displayed for a certain period of time (whoIsWatchingSession)
 * @returns object UseWhoIsWatching
 */
export const useWhoIsWatching = (): UseWhoIsWatching => {
  const featureToggleWhoIsWatching = useSelector(getFeatureToggleWhoIsWatching);
  const shouldDisplayWhoIsWatching = useSelector(shouldDisplayWhoIsWatchingSelector);
  const whoIsWatchingSettings = useSelector(getWhoIsWatchingSettingsSelector);
  const user = useSelector(userSelector);
  const isTvDevice = useSelector(displayTVModeSelector);

  const [showWhoIsWatching, setShowWhoIsWatching] = useState(false);

  /**
   * At startup only, check if we have to display the whoIsWatching screen
   */
  useEffect(
    () => {
      if (featureToggleWhoIsWatching && shouldDisplayWhoIsWatching) {
        setShowWhoIsWatching(true);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * Listen change on whoIsWatchingUserSettings.inSession to hide the whoIsWatching screen
   * inSession become true when user select a profile on the whoIsWatching screen (see WhoIsWatching.tsx)
   */
  useEffect(() => {
    if (whoIsWatchingSettings.inSession && whoIsWatchingSettings.forceDisplay === 'auto') {
      setShowWhoIsWatching(false);
    }
  }, [whoIsWatchingSettings]);

  /**
   * Run the interval to set the cookie every minute if:
   * - WhoIsWatching state inSession is true (the screen has already displayed)
   * - WhoIsWatching is not disabled by user settings
   * - User is authenticated
   * - Feature WhoIsWatching is enabled (featureToggleWhoIsWatching)
   * - Recommandations is enabled
   *
   * We set the cookie session every minute to avoid display whoIsWatching screen when reload the app (switch profile, activate/desactivate recommandations, refresh rights, accept or update consent)
   */
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (
      featureToggleWhoIsWatching &&
      whoIsWatchingSettings.inSession &&
      !whoIsWatchingSettings.disabled &&
      user.authenticated &&
      user.reco
    ) {
      intervalId = setInterval(() => {
        setCookieWhoIsWatchingSession(isTvDevice);
      }, INTERVAL_SET_COOKIE_SESSION);
    }

    return () => {
      if (intervalId !== undefined) {
        clearInterval(intervalId);
        intervalId = undefined;
      }
    };
  }, [user.authenticated, user.reco, whoIsWatchingSettings, featureToggleWhoIsWatching, isTvDevice]);

  return { showWhoIsWatching };
};
