import { isSomeEnum } from '@canalplus/mycanal-commons';
import { PersoLists } from '@canalplus/sdk-hodor';
import { ErrorInfo, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getPublicConfig } from '../../helpers/config/config-helper';
import Logger from '../../helpers/logger/logger-helper';
import { FromProp } from '../../server/modules/fetchWithQuery/types';
import { errorTemplateTextSelector, errorTemplateTitleSelector } from '../../store/slices/error-selectors';
import LoadableContentGrid from '../../templates/ContentGrid';
import LoadableContentGridPerso from '../../templates/ContentGridPerso';
import LoadableCreativeMediaConnected from '../../templates/CreativeMedia';
import LoadableDetailLight from '../../templates/DetailLight';
import LoadableDetailV5 from '../../templates/DetailV5';
import LoadableDownloadToGo from '../../templates/DownloadToGo';
import LoadableError from '../../templates/Error';
import LoadableFunnelTvod from '../../templates/FunnelTvod/components/LoadableFunnelTvod';
import { FunnelHodorStep } from '../../templates/FunnelTvod/stores/constants';
import LoadableGabaritList from '../../templates/GabaritList';
import LoadableLandingV5 from '../../templates/LandingV5';
import LoadableMosaic from '../../templates/Mosaic';
import LoadableMyAccountIdp from '../../templates/MyAccountIdp';
import LoadablePrivacyManager from '../../templates/PrivacyManager';
import LoadableSearchStandalone from '../../templates/SearchStandalone';
import LoadableSectionsList from '../../templates/SectionsList';
import LoadableSettings from '../../templates/Settings';
import LoadableShowcase from '../../templates/Showcase';
import LoadableSlideshow from '../../templates/Slideshow';
import LoadableStub from '../../templates/Stub';
import LoadableTextBrut from '../../templates/TextBrut';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { LoadableMyAccountConnected } from '../MyAccount/LoadableMyAccountConnected';
import { log } from '../VisualDebugger/html-logger';

export type TemplateProps = {
  animationEnded?: boolean;
  onFocusable?: () => void;
} & Pick<Routing.IMyCanalOnClick, 'displayName' | 'displayTemplate' | 'perso' | 'URLPage' | 'parameters'>;

/** Switch between displayTemplate */
function Template({
  displayName = '',
  onFocusable,
  perso,
  parameters,
  URLPage = '',
  displayTemplate,
}: TemplateProps): JSX.Element | null {
  const errorTemplateText = useSelector(errorTemplateTextSelector);
  const errorTemplateTitle = useSelector(errorTemplateTitleSelector);

  const onError = useCallback(
    (error: Error, info: ErrorInfo): void => {
      Logger.error(`An error has occurred during while rendering the ${displayTemplate} template`);
      log({
        level: 'error',
        tags: ['<Template />'],
        message: `${error.message}${info.componentStack}`,
      });
    },
    [displayTemplate]
  );

  // `displayTemplate` is empty until we receive the hodor response
  if (!displayTemplate) {
    return null;
  }

  const publicConfig = getPublicConfig();

  const commonProps = {
    from: 'root',
    onClickParameters: parameters,
    url: URLPage,
  } satisfies {
    url: Routing.IMyCanalOnClick['URLPage'];
    onClickParameters: Routing.IMyCanalOnClick['parameters'];
  } & FromProp;

  const persoType = isSomeEnum(PersoLists)(perso) ? perso : undefined;

  const renderTemplate = (): JSX.Element => {
    switch (displayTemplate) {
      case publicConfig.TEMPLATE.CONTENT_GRID:
        if (persoType) {
          return (
            <LoadableContentGridPerso
              header={{ title: displayName }}
              onFocusable={onFocusable}
              persoType={persoType}
              {...commonProps}
            />
          );
        }
        return <LoadableContentGrid header={{ title: displayName }} onFocusable={onFocusable} {...commonProps} />;
      case publicConfig.TEMPLATE.MOSAIC:
        return <LoadableMosaic nbPlaceholderItems={20} {...commonProps} />;

      case publicConfig.TEMPLATE.DETAIL_LIGHT:
        return <LoadableDetailLight {...commonProps} onFocusable={onFocusable} />;

      case publicConfig.TEMPLATE.DETAIL_SEASON:
      case publicConfig.TEMPLATE.DETAIL_SHOW:
      case publicConfig.TEMPLATE.DETAIL_PAGE:
      case publicConfig.TEMPLATE.QUICKTIME:
        return <LoadableDetailV5 onFocusable={onFocusable} {...commonProps} />;

      case publicConfig.TEMPLATE.PAYMENT_MEANS:
      case publicConfig.TEMPLATE.CONTEXTUAL_OFFER: {
        return <LoadableFunnelTvod from="root" hodorStep={displayTemplate as FunnelHodorStep} url={URLPage} fullPage />;
      }

      case publicConfig.TEMPLATE.CREATIVE_MEDIA:
        return <LoadableCreativeMediaConnected {...commonProps} />;

      case publicConfig.TEMPLATE.GABARIT_LIST:
        return <LoadableGabaritList onFocusable={onFocusable} {...commonProps} />;

      case publicConfig.TEMPLATE.ERROR:
        return (
          <LoadableError from={commonProps.from} url={URLPage} title={errorTemplateTitle} text={errorTemplateText} />
        );

      case publicConfig.TEMPLATE.LANDING:
        return <LoadableLandingV5 onFocusable={onFocusable} {...commonProps} />;

      case publicConfig.TEMPLATE.TEXT_BRUT:
        return <LoadableTextBrut {...commonProps} />;

      case publicConfig.TEMPLATE.SETTINGS:
        return <LoadableSettings {...commonProps} />;

      case publicConfig.TEMPLATE.MY_ACCOUNT:
        return <LoadableMyAccountConnected />;

      case publicConfig.TEMPLATE.MY_ACCOUNT_IDP:
        return <LoadableMyAccountIdp onFocusable={onFocusable} {...commonProps} />;

      case publicConfig.TEMPLATE.SHOWCASE:
        return <LoadableShowcase onFocusable={onFocusable} {...commonProps} />;

      case publicConfig.TEMPLATE.DOWNLOAD_TO_GO:
        return <LoadableDownloadToGo {...commonProps} />;

      case publicConfig.TEMPLATE.SECTIONS_LIST:
        return <LoadableSectionsList onFocusable={onFocusable} {...commonProps} />;

      case publicConfig.TEMPLATE.SEARCH:
        return <LoadableSearchStandalone onFocusable={onFocusable} {...commonProps} />;

      case publicConfig.TEMPLATE.SLIDESHOW:
        return <LoadableSlideshow onFocusable={onFocusable} {...commonProps} />;

      case publicConfig.TEMPLATE.STUB:
        return <LoadableStub onFocusable={onFocusable} {...commonProps} />;

      case publicConfig.TEMPLATE.PRIVACY_MANAGER:
        return <LoadablePrivacyManager onFocusable={onFocusable} {...commonProps} />;

      default:
        return <LoadableError from={commonProps.from} />;
    }
  };

  return <ErrorBoundary onError={onError}>{renderTemplate()}</ErrorBoundary>;
}

export default memo(Template);
