import { createContext, ReactNode, useContext } from 'react';
import { useSelector } from 'react-redux';
import { isImmersiveSelector } from '../../store/slices/immersive-selectors';
import { isSearchActiveSelector } from '../../store/slices/search-selectors';
import { LocationStateContext } from '../../typings/routing';

const RoutingContext = createContext<LocationStateContext>('page');

export function RoutingContextProvider({
  routingContext,
  children,
}: {
  routingContext: LocationStateContext;
  children: ReactNode;
}): JSX.Element {
  return <RoutingContext.Provider value={routingContext}>{children}</RoutingContext.Provider>;
}

export function useRoutingContext(): LocationStateContext {
  return useContext(RoutingContext);
}

/**
 * if the current context is not hidden by other routing context
 * By order of who is above who:
 * MainPlayer > Modal (slideshowNotifications) > Immersive > Search > Page
 * for example:
 * a Template inside routing context "page", will be below "immersive", "search" and "slideshowNotifications" routing context
 * @returns boolean
 */
export function useIsOnCurrentRoutingContext(): boolean {
  const routingContext = useRoutingContext();
  const isImmersiveOpen = useSelector(isImmersiveSelector);
  const isSearchOpen = useSelector(isSearchActiveSelector);

  if (routingContext === 'slideshowNotifications') {
    return true;
  }

  if (isImmersiveOpen) {
    return routingContext === 'immersive';
  }

  if (isSearchOpen) {
    return routingContext === 'search';
  }

  // Only lowest layer remains
  return routingContext === 'page';
}
