import { cva } from 'class-variance-authority';

export const listCVA = cva(['items-center justify-between font-hind flex flex-row border-solid'], {
  variants: {
    device: {
      tv: [
        'rounded-dt-radius-xlarge',
        'text-30',
        'px-24',
        'py-12',
        'tv-focus-self:scale-tv-focus',
        'tv-focus-self:bg-dt-theme-tv-surface-list-list-focus',
        'border-dt-border-regular',
        'tv-focus-self:border-dt-theme-tv-surface-list-list-focus',
      ],
      web: [
        'rounded-dt-radius-small',
        'text-20',
        'px-16',
        'py-14',
        'bg-dt-theme-surface-list-row-list-row',
        'border border-dt-theme-border-list-row-list-row',
      ],
    },
    isDisabled: {
      false: 'cursor-pointer',
    },
  },
  compoundVariants: [
    {
      device: 'tv',
      isDisabled: true,
      className: 'bg-transparent border-dt-theme-tv-border-list-list',
    },
    {
      device: 'tv',
      isDisabled: false,
      className: 'bg-dt-theme-tv-surface-list-list border-dt-theme-tv-surface-list-list',
    },
  ],
});

export const listTitleCVA = cva(['line-clamp-2 text-start'], {
  variants: {
    device: {
      tv: 'text-dt-theme-tv-text-list-list-row-title-default tv-focus-parent:text-dt-theme-tv-text-list-list-row-title-focus text-30',
      web: 'text-dt-theme-text-list-row-list-title text-18',
    },
  },
});

export const listTextCVA = cva(['text-start'], {
  variants: {
    device: {
      tv: 'tv-focus-parent:text-dt-theme-tv-text-list-list-row-subtitle-focus text-30 text-dt-theme-tv-text-list-list-row-subtitle-default',
      web: 'text-16 text-dt-theme-text-list-row-list-row-subtitle',
    },
  },
});

export const rightElementContainerCVA = cva(['absolute flex items-center'], {
  variants: {
    device: { tv: 'w-min min-w-50 h-50', web: 'w-min min-w-24 h-24' },
  },
});

export const leftElementContainerCVA = cva(['leading-5'], {
  variants: {
    device: { tv: 'flex', web: 'flex items-start' },
    layout: {
      inline: undefined,
      stacked: undefined,
    },
  },
  compoundVariants: [
    {
      device: 'web',
      layout: 'inline',
      className: 'flex-row items-baseline',
    },
    {
      device: 'web',
      layout: 'stacked',
      className: 'flex-col',
    },
  ],
});

export const spacingLeftContainerCVA = cva(undefined, {
  variants: {
    device: { tv: 'ml-dt-spacing-200', web: undefined },
    layout: {
      inline: undefined,
      stacked: undefined,
    },
  },
  compoundVariants: [
    {
      device: 'web',
      layout: 'inline',
      className: 'mt-0 ml-dt-spacing-100',
    },
    {
      device: 'web',
      layout: 'stacked',
      className: 'mt-dt-spacing-50 ml-dt-spacing-none',
    },
  ],
});

export const listTextRightColorCVA = cva(undefined, {
  variants: {
    device: {
      tv: [
        'tv-focus-parent:text-dt-theme-tv-text-list-list-row-label-focus',
        'text-dt-theme-tv-text-list-list-row-label-default',
      ],
      web: 'text-16',
    },
    hasLineThrough: { true: undefined, false: undefined },
  },
  compoundVariants: [
    {
      device: 'web',
      hasLineThrough: true,
      className: 'text-dt-theme-text-list-row-list-row-price',
    },
    {
      device: 'web',
      hasLineThrough: false,
      className: 'text-dt-theme-text-list-row-list-row-label',
    },
  ],
});

export const rightContainerColorCVA = cva(undefined, {
  variants: {
    device: {
      tv: 'tv-focus-parent:fill-dt-theme-tv-icon-list-icon-focus fill-dt-theme-tv-icon-list-icon',
      web: undefined,
    },
    hasLineThrough: { true: undefined, false: undefined },
  },
  compoundVariants: [
    {
      device: 'web',
      hasLineThrough: true,
      className: 'fill-dt-theme-icon-list-row-chevron-price',
    },
    {
      device: 'web',
      hasLineThrough: false,
      className: 'fill-dt-theme-icon-list-row-chevron',
    },
  ],
});
