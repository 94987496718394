import { addQueryParam } from '@canalplus/mycanal-commons';
import { Template } from '@canalplus/sdk-hodor';
import { LocationDescriptor } from 'history';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { StrateMode } from '../../../constants/strates';
import { TemplateTypes } from '../../../constants/templateTypes';
import { ThemeColor } from '../../../constants/themeColor';
import { Queries } from '../../../constants/url';
import { getPublicConfig } from '../../../helpers/config/config-helper';
import { useAppHistory, useAppLocation } from '../../../helpers/hooks/reactRouter';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { useAuthFromExternalSiteWithIDPToken } from '../../../helpers/hooks/useAuthFromExternalSiteWithIDPToken';
import { useSigninRedirect } from '../../../helpers/pass/useSigninRedirect';
import { clientSideOneShopRedirection, clientSideSelectRegionRedirection } from '../../../helpers/user/user-helper';
import { isR7Available } from '../../../helpers/window/window-helper';
import { offerLocationSelector, platformSelector } from '../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { immersiveSelector } from '../../../store/slices/immersive-selectors';
import { pageSelector } from '../../../store/slices/page-selectors';
import { launchPlayerFullScreen } from '../../../store/slices/player-thunk';
import { idpTokenSelector, isKidsProfileSelector } from '../../../store/slices/user-selectors';
import type { LocationState } from '../../../typings/routing';
import { getLocationState } from '../helpers/getLocationState';
import { LinkerProps, LinkerSettings } from '../types';
import { getOnClickGenerated, redirectRouter } from './helpers/useLinkerHelper';

export type UseLinker = {
  /**
   * Returns settings for the Linker component. Useful when trying to get `onClick`, `href` and `target` values for your action element
   * @param props LinkerProps
   * @returns LinkerSettings The linker settings
   * @example
      const linkerSettings = getLinkerSettings({ data: { onClick: button.onClick }});
      if(linkerSettings.href) {
        return <a className={styles.defaultTemplate_button} onClick={linkerSettings.onClick} href={linkerSettings.href} target={linkerSettings.target}>
          My Link
        </a>
      }else {
        return <button type="button" className={styles.defaultTemplate_button} onClick={linkerSettings.onClick}>
          My Link
        </button>
      }
   */
  getLinkerSettings: (props: LinkerProps) => LinkerSettings;
};

/**
 * Give a method (getLinkerSettings) to generate LinkerSettings.
 * Useful when trying to get `onClick`, `href` and `target` values for your action element
 * @example
    const { getLinkerSettings } = useLinker();
 * @returns UseLinker object { getLinkerSettings }
 */
const useLinker = (): UseLinker => {
  const location = useAppLocation();
  const history = useAppHistory();

  const dispatch = useAppDispatch();

  const idpToken = useSelector(idpTokenSelector);
  const immersiveState = useSelector(immersiveSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const offerLocation = useSelector(offerLocationSelector);
  const pageState = useSelector(pageSelector);
  const platform = useSelector(platformSelector);
  const isKids = useSelector(isKidsProfileSelector);
  const { authenticate } = useAuthFromExternalSiteWithIDPToken(idpToken);
  const handleConnectClick = useSigninRedirect();

  const getLinkerSettings = useCallback(
    (linkerProps: LinkerProps): LinkerSettings => {
      const publicConfig = getPublicConfig();
      const { data, objKey = 'onClick', target = '_blank', replace } = linkerProps;
      const { mainOnClick, subOnClick, context, contentID } = data || {};
      const { displayTemplate, path, URLWebsite, displayMode, target: onClickTarget } = subOnClick || mainOnClick || {};

      const linkTarget = isTvDevice ? '_self' : onClickTarget || target;
      const query = path?.split('?')[1] || '';
      const brand = publicConfig.api.hodor.defaultAppKey;

      const onOneShopClick = (contentId?: string) => clientSideOneShopRedirection(contentId);
      const onSelectRegionClick = (contentId?: string) => clientSideSelectRegionRedirection(contentId);
      const handleClick = getOnClickGenerated(linkerProps, dispatch, isTvDevice, offerLocation, publicConfig, platform);

      if (displayTemplate === Template.ExternalSiteWithIDPToken && URLWebsite) {
        return {
          onClick: () => {
            authenticate(URLWebsite);
          },
        };
      }

      if (displayTemplate === publicConfig.TEMPLATE.EXTERNAL_SITE && URLWebsite) {
        return { href: URLWebsite, target: linkTarget, onClick: handleClick };
      }

      if (displayTemplate === publicConfig.TEMPLATE.UPDATE_RIGHTS) {
        const refreshRightRef = addQueryParam(window.location.href, Queries.RefreshRight, 'true');
        return { href: refreshRightRef, target: '_self', onClick: handleClick };
      }

      if (displayTemplate === publicConfig.TEMPLATE.DOWNLOAD_MANAGER) {
        return { onClick: handleClick };
      }

      if (displayTemplate === publicConfig.TEMPLATE.AUTHENTICATION) {
        return { onClick: () => handleConnectClick() };
      }

      if (displayTemplate === publicConfig.TEMPLATE.SELECT_REGION) {
        return {
          onClick: () => {
            onSelectRegionClick(contentID);
          },
        };
      }

      if (displayTemplate === TemplateTypes.LAUNCH_ONE_SHOP) {
        return {
          onClick: () => {
            onOneShopClick(contentID);
          },
        };
      }

      if (displayTemplate === Template.Player && objKey !== StrateMode.LiveTv) {
        return { onClick: handleClick };
      }

      const state = getLocationState({
        context,
        immersiveState,
        location,
        pageState,
        replace,
        ...(mainOnClick ? { mainOnClick } : { subOnClick }),
      });

      const to: LocationDescriptor<LocationState> = {
        pathname: query ? path?.split('?')[0] : path,
        search: query,
        state: Object.keys(state).length ? state : undefined,
      };

      // In the case is a navigation with the router, we return all information needed for routing
      return {
        href: to.pathname,
        target: '_self',
        onClickWithoutRouting:
          displayMode === publicConfig.TEMPLATE.FULLSCREEN || objKey === StrateMode.LiveTv
            ? (event) => {
                event.persist();
                // TODO: refacto data type
                dispatch(launchPlayerFullScreen({ event, data: data as any, type: objKey }));
                const { trackingContext } = data?.mainOnClick || {};
                const launchPlayerPageName = `${brand} - Live TV - Lecture Player Live`;

                if (isTvDevice && isR7Available()) {
                  // TODO: Delete this condition when OneCore Will be ready to handle this case
                  window.R7('trackSendPageView', {
                    data: {
                      ...trackingContext,
                      ...(trackingContext?.context_type && { prop17: trackingContext?.context_type }),
                      ...(trackingContext?.contextDetail && { prop18: trackingContext?.contextDetail }),
                      eVar37: 'Live',
                      eVar38: 'Regarder Live',
                      themeColor: ThemeColor.Dark,
                      profile_kids: isKids,
                      page_name: launchPlayerPageName,
                    },
                    name: launchPlayerPageName,
                  });
                }
                handleClick(event);
              }
            : handleClick,
        onClick:
          displayMode === publicConfig.TEMPLATE.FULLSCREEN || objKey === StrateMode.LiveTv
            ? (event) => {
                event.preventDefault();
                event.persist();
                // TODO: refacto data type
                dispatch(launchPlayerFullScreen({ event, data: data as any, type: objKey }));
                handleClick(event);
                redirectRouter(history, to, replace);
              }
            : (event) => {
                event.preventDefault();
                handleClick(event);
                redirectRouter(history, to, replace);
              },
        to,
      };
    },
    [
      authenticate,
      dispatch,
      handleConnectClick,
      history,
      immersiveState,
      isKids,
      isTvDevice,
      location,
      offerLocation,
      pageState,
      platform,
    ]
  );

  return { getLinkerSettings };
};

export default useLinker;
