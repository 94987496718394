import { AnchorHTMLAttributes, AriaAttributes, ButtonHTMLAttributes } from 'react';
import { DiveIconPosition, DiveProps } from '../../../types/Dive.types';

export enum HeaderNavVariant {
  Priority = 'priority',
  Scroll = 'scroll',
}

export type HeaderNavItem = {
  /**
   * Label of the item
   */
  label: string;
  /**
   * ID of the item
   */
  id?: string;
  /**
   * URL of the item (<a href/>)
   */
  href?: AnchorHTMLAttributes<HTMLAnchorElement>['href'];
  /**
   * Custom wrapper element to be used instead of `<a href/>` default element
   */
  wrapper?: React.ReactElement;
  /**
   * Icon to be displayed in the item
   */
  icon?: React.ReactElement;
  /**
   * Icon placement in the item
   *
   * @default 'left'
   */
  iconPosition?: `${DiveIconPosition}`;
  /**
   * onClick event to be performed besides headerNavItem behavior
   *
   */
  onClick?: ButtonHTMLAttributes<HTMLAnchorElement>['onClick'];
};

export type HeaderNavProps = {
  /**
   * List of items to be displayed in the nav
   */
  items: HeaderNavItem[];
  /**
   * Index of the active item (first item being at index 0)
   */
  activeIndex?: number;
  /**
   * ID of the nav
   */
  id?: string;
  /**
   * Variant of the nav
   *
   * @example 'priority' | 'scroll'
   * @default 'priority'
   */
  variant?: `${HeaderNavVariant}`;
  /**
   * Whether the nav should center the active item when it is in `scroll` variant
   *
   * @default true
   */
  shouldCenterActiveItem?: boolean;
  /**
   * Boolean indicating whether the nav should be fully transparent.
   *  - Disables left and right overflow gradients for `scroll` variant
   *
   * @default false
   */
  isTransparent?: boolean;
  /**
   * Label of the dropdown when the nav is in `priority` variant
   */
  dropdownLabel?: string;
} & AriaAttributes &
  DiveProps;
