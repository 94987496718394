import React, { AriaAttributes, ButtonHTMLAttributes, MouseEventHandler, ReactElement } from 'react';
import { DiveFontFamily, DiveIconPosition, DiveProps } from '../../../types/Dive.types';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Text = 'text',
  Slim = 'slim',
}

export enum ButtonWidth {
  Fit = 'fit',
  Fixed = 'fixed',
  Full = 'full',
}

export type ButtonWrapperProps = {
  /**
   * Button id.
   */
  id: ButtonProps['id'];
  /**
   * Button disabled state.
   */
  disabled: ButtonProps['disabled'];
  /**
   * Button className.
   */
  className: ButtonProps['className'];
  /**
   * Button onClick handler.
   */
  onClick: ButtonProps['onClick'];
  /**
   * Button content
   */
  children: JSX.Element;
  /**
   * Button ref.
   */
  ref: React.ForwardedRef<HTMLElement>;
} & Omit<AriaAttributes, 'aria-disabled'>;

export type ButtonProps = {
  /**
   * Button label.
   */
  children?: ReactElement | string;
  /**
   * Icon `ReactElement` to be displayed inside the Button.
   *
   * See `iconPosition` prop on how to handle its positioning relative to the Button label.
   */
  icon?: React.ReactElement;
  /**
   * Position of the `icon` element relative to the `children` label.
   *
   * @defaultValue 'left'
   */
  iconPosition?: `${DiveIconPosition}`;
  /**
   * Style variant of the Button.
   *
   * @defaultValue 'primary'
   */
  variant?: `${ButtonVariant}`;
  /**
   * Width variant of the Button:
   * - `fit`: fits width based on content.
   * - `fixed`: takes a fixed width of `30rem` on TV, `11rem` on Desktop, and `10rem` on Mobile.
   * - `full`: takes the full width of its container.
   *
   * @defaultValue 'fit'
   */
  width?: `${ButtonWidth}`;
  /**
   * font-family variant of the Button.
   *
   * @defaultValue 'canal'
   */
  font?: `${DiveFontFamily}`;
  /**
   * attribute onClick of the element
   */
  onClick?: MouseEventHandler<HTMLElement>;
  /**
   * A function that makes it possible to render the Button as another HTML Element than the default `<button>`. The classic use-case of this function is to use it to render an HTML Anchor `<a>` with the visual styles of the Button component:
   *
   * @example
   * <Button
      renderWrapper={({id, disabled, className, onClick, children}) => {
        return (
          <a
            id={id}
            className={className}
            onClick={onClick}
            aria-disabled={disabled}
            {...(disabled ? {} : { href: "https://canalplus.com/" })}
          >
            {children}
          </a>
        );
      }}
     >
       Label
     </Button>
   */
  renderWrapper?: (props: ButtonWrapperProps) => React.ReactElement;
  /**
   * This Boolean attribute prevents the user from interacting with the button: it cannot be pressed or focused.
   *
   * @defaultValue false
   */
  disabled?: ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
  /**
   * The default behavior of the button. Possible values are: `submit`, `reset`, and `button`.
   *
   * @defaultValue 'button'
   */
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  /**
   * The id of the Button.
   */
  id?: ButtonHTMLAttributes<HTMLButtonElement>['id'];
  /**
   * The ARIA role for the Button.
   */
  role?: ButtonHTMLAttributes<HTMLButtonElement>['role'];
} & DiveProps &
  Omit<AriaAttributes, 'aria-disabled'>;
