import { PROFILES_LIMIT, ProfileModal } from '@canalplus/mycanal-commons';
import { ModalV2, ModalV2Size, VirtualKeyboardProvider } from '@canalplus/mycanal-sharedcomponent';
import { KEY_BACK, Layer, useKeyCatcher, useStore as useStoreNavigation } from '@canalplus/one-navigation';
import { ApiV2NavigationSettings } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { ThemeColor } from '../../../../constants/themeColor';
import { ProfileEvents, ProfileType } from '../../../../constants/tracking';
import { setTokenCMS } from '../../../../helpers/cookie/cookie-helper';
import { useAppLocation } from '../../../../helpers/hooks/reactRouter';
import { useAppDispatch } from '../../../../helpers/hooks/useAppDispatch';
import Logger from '../../../../helpers/logger/logger-helper';
import { LAYER_SETTINGS_MANAGE_PROFILES } from '../../../../helpers/oneNavigation/layers';
import { sendProfileTrackingEvent } from '../../../../helpers/tracking/tracking-helper';
import {
  getRedirectPathOnWhoIsWatchingProfile,
  getRedirectUrlOnSelectProfile,
} from '../../../../helpers/url/url-helper';
import { performAuthenticate } from '../../../../services/Hodor/Authenticate/performAuthenticate';
import { setCurrentProfile } from '../../../../services/profiles/profileStorageService';
import { setApplicationHodorSdkConfig } from '../../../../store/slices/application';
import {
  hodorSdkConfigSelector,
  kidsHomeUrlSelector,
  offerLocationSelector,
  userSelector,
} from '../../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../../store/slices/displayMode-selectors';
import { pageURLSelector } from '../../../../store/slices/page-selectors';
import { setProfileView, setSelectedProfile, wipeProfileInfos } from '../../../../store/slices/profilesModal';
import { modalCurrentViewSelector, selectedProfileSelector } from '../../../../store/slices/profilesModal-selectors';
import { displayProfileMenu, userListProfile, userListProfiles } from '../../../../store/slices/user';
import { profilesSelector, segTypeSelector } from '../../../../store/slices/user-selectors';
import type { IState } from '../../../../store/types/State-type';
import LoadableMaxProfilesReachedModal from '../../../Modal/MaxProfilesReachedModal/LoadableMaxProfilesReachedModal';
import ProfilesViewHandler from '../../ProfilesViewHandler';
import { getPrevProfilesView } from '../helpers';
import styles from './ProfileModalSelectorMyCanal.css';

export type UseProfileSelector = {
  /**
   * Action to use when click on a profile
   * On a profile click, we need to replace the current profile in the localStorage
   * And we need to reload the application to fetch all the perso strates for
   * the newly selected profile
   * @param profileSelectedArg The profile clicked
   * @param event React.MouseEvent
   */
  handleProfileClick: (profileSelectedArg: ApiV2Profile, event: React.MouseEvent) => void;
  /**
   * Action to use when click on a profile for the screen whoIsWatching
   * On a profile click from onBoarding select profile (Who is Watching screen),
   * We need to replace the current profile in the localStorage and in store
   * And we need to call authenticate and redirect to the good path
   * @param profileSelectedArg The profile clicked
   * @param event React.MouseEvent
   */
  handleProfileClickWithAuth: (profileSelectedArg: ApiV2Profile, event: React.MouseEvent) => Promise<void>;
  /**
   * Action to use when click on add a profile
   */
  handleAddProfileClick: () => void;
  /**
   * Action to use when click on edit a profile
   */
  handleEditButton: () => void;
  /**
   * Render the JSX Elements for the modals
   */
  renderModals: () => JSX.Element;
  /**
   * Force to close the profiles modal
   */
  closeProfilesModal: () => void;
};

/**
 * Hook get the actions for adding and editing a profile and a renderer for modals associated. It returns :
 * - The action when click on a profile
 * - The action when click add a profile
 * - The action when click edit a profile
 * - The modals renderer to edit or adding profile
 * @param binderLayerFrom Binder Layer we come from when we use this hook.
 * It's to restore focus on this layer when we close popins of profiles selector
 */
export const useProfileSelector = (binderLayerFrom: number): UseProfileSelector => {
  const dispatch = useAppDispatch();
  const location = useAppLocation();

  const currentView: ProfileModal = useSelector(modalCurrentViewSelector);
  const profileSelected = useSelector(selectedProfileSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const offerLocation = useSelector(offerLocationSelector);
  const pageURL = useSelector(pageURLSelector);
  const profiles = useSelector(profilesSelector);
  const segType = useSelector(segTypeSelector);
  const kidsHomeUrl = useSelector(kidsHomeUrlSelector);
  const hodorSdkConfig = useSelector(hodorSdkConfigSelector);
  const userData = useSelector(userSelector);
  const store = useStore<IState>();

  const [showProfilesViewHandlerModal, setShowProfilesViewHandlerModal] = useState(false);
  const [showMaxProfilesReachedModal, setShowMaxProfilesReachedModal] = useState(false);

  const onUpdateCurrentProfile = useCallback(
    (
      profileSelectedArg: ApiV2Profile,
      event: React.MouseEvent,
      profileEvent: ProfileEvents,
      updateStore: boolean = false
    ) => {
      // update cookies
      setCurrentProfile(profileSelectedArg);

      // Update in redux store the selected profile and the list sorted
      if (updateStore) {
        dispatch(userListProfile(profileSelectedArg));
        dispatch(userListProfiles(profiles));
      }

      const { profileId } = profileSelectedArg || {};
      const profileType = profileSelectedArg.isKidsProfile ? ProfileType.Kids : ProfileType.Standard;

      sendProfileTrackingEvent({
        activeProfiles: profiles.length,
        event,
        isKids: !!profileSelectedArg.isKidsProfile,
        offerLocation,
        pageURL,
        profileId: profileId?.toString(),
        profileType,
        segType,
        themeColor: ThemeColor.Dark,
        type: profileEvent,
        version: $_BUILD_APP_VERSION,
        isTvDevice,
      });
    },
    [profiles, pageURL, segType, dispatch, offerLocation, isTvDevice]
  );

  const handleProfileClick = useCallback(
    async (profileSelectedArg: ApiV2Profile, event: React.MouseEvent) => {
      onUpdateCurrentProfile(profileSelectedArg, event, ProfileEvents.Change);

      const redirectPathOnSelectProfile = getRedirectUrlOnSelectProfile(
        offerLocation,
        location.pathname,
        isTvDevice,
        (profileSelectedArg.isKidsProfile && kidsHomeUrl) || undefined
      );

      if (redirectPathOnSelectProfile) {
        window.location.href = redirectPathOnSelectProfile;
      }
    },
    [offerLocation, isTvDevice, kidsHomeUrl, onUpdateCurrentProfile, location]
  );

  const handleProfileClickWithAuth = useCallback(
    async (profileSelectedArg: ApiV2Profile, event: React.MouseEvent) => {
      try {
        onUpdateCurrentProfile(profileSelectedArg, event, ProfileEvents.Select, true);

        if (!hodorSdkConfig) {
          throw new Error('hodorSdkConfig is undefined');
        }

        const configWithInit = {
          ...hodorSdkConfig,
          profileId: profileSelectedArg.profileId?.toString(),
        };

        // Cass hodor authenticate with the selected profile info
        const { authenticateResponse, hodorSdkConfig: hodorSdkConfigFromAuthenticate } = await performAuthenticate({
          configWithInit,
          store,
          isKidsProfile: profileSelectedArg.isKidsProfile,
          shouldCollectUserData: userData.settings.hasUserDataCollected,
        });

        dispatch(setApplicationHodorSdkConfig(hodorSdkConfigFromAuthenticate));

        // Define the tokenCMS cookie
        // Needed for Akamai Cache
        if (authenticateResponse) {
          setTokenCMS(authenticateResponse.token);
        }

        // Redirect to the good path
        // if the selected profile is kids, redirect to the home kids
        const { kidsHomeUrl: kidsUrlFromAuth } =
          (authenticateResponse.settings as ApiV2NavigationSettings & { kidsHomeUrl: string }) || {};

        const redirectPath = getRedirectPathOnWhoIsWatchingProfile(
          offerLocation,
          location.pathname,
          (profileSelectedArg.isKidsProfile && kidsUrlFromAuth) || undefined
        );

        // Need manually update location because the router is not yet instantiate, history.replace() has no effect
        location.pathname = redirectPath;
        location.state = { ...location.state, fromSSR: false };
      } catch (error) {
        Logger.error(`ProfileSelector > Authenticate error ${error}`);
      }
    },
    [
      offerLocation,
      location,
      hodorSdkConfig,
      onUpdateCurrentProfile,
      userData.settings.hasUserDataCollected,
      store,
      dispatch,
    ]
  );

  const handleAddProfileClick = useCallback(() => {
    if (profiles.length < PROFILES_LIMIT) {
      dispatch(wipeProfileInfos());
      dispatch(setSelectedProfile());
      dispatch(setProfileView(ProfileModal.ProfileCreationViewFromLanding));
      setShowProfilesViewHandlerModal(true);
      dispatch(displayProfileMenu(false));
    } else {
      setShowMaxProfilesReachedModal(true);
    }
  }, [displayProfileMenu, profiles, setProfileView]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditButton = () => {
    dispatch(setProfileView(ProfileModal.ProfileListView));
    setShowProfilesViewHandlerModal(true);
  };

  const profilesViewHandlerModalSize = isTvDevice ? ModalV2Size.FullScreen : undefined;

  const hasPreviousProfileView = getPrevProfilesView(currentView, profileSelected);

  /**
   * On TV, if the user use a back navigation,
   * we need to display the userMenu by closing the modal in 2 cases:
   *
   * - when the user is creating a profile and he clicks on the back button
   * => we don't want to display the <ProfilesList> on TV (on desktop, the <ProfilesList>t is displayed)
   *
   * - when the user is on the <ProfilesList> and he clicks on the back button
   * => we want to close the modal and display the <SectionsListTemplate> (userMenu on TV)
   *
   */
  const shouldCloseModal =
    ((currentView === ProfileModal.ProfileCreationView ||
      currentView === ProfileModal.ProfileCreationViewFromLanding) &&
      hasPreviousProfileView === ProfileModal.ProfileListView) ||
    (currentView === ProfileModal.ProfileListView && hasPreviousProfileView === undefined) ||
    currentView === ProfileModal.ProfileCreationViewFromLanding;

  const storeNavigation = useStoreNavigation();
  useEffect(() => {
    if (showProfilesViewHandlerModal) {
      storeNavigation.setActiveLayer(LAYER_SETTINGS_MANAGE_PROFILES);
      storeNavigation.focusDefault();
    } else {
      storeNavigation.setActiveLayer(binderLayerFrom);
    }
  }, [showProfilesViewHandlerModal, storeNavigation, binderLayerFrom]);

  const handleBack = useCallback(() => {
    if (isTvDevice) {
      return shouldCloseModal
        ? setShowProfilesViewHandlerModal(false)
        : dispatch(setProfileView(hasPreviousProfileView));
    }

    return hasPreviousProfileView
      ? dispatch(setProfileView(hasPreviousProfileView))
      : setShowProfilesViewHandlerModal(false);
  }, [dispatch, isTvDevice, hasPreviousProfileView, shouldCloseModal]);

  useKeyCatcher(KEY_BACK, handleBack, LAYER_SETTINGS_MANAGE_PROFILES);

  const renderModals = () => {
    return (
      <>
        {showProfilesViewHandlerModal && (
          <VirtualKeyboardProvider>
            <ModalV2
              onClose={() => setShowProfilesViewHandlerModal(false)}
              onBack={hasPreviousProfileView && (() => handleBack())}
              isTvDevice={isTvDevice}
              e2e="manageProfilesModal"
              size={profilesViewHandlerModalSize}
              type="profile"
              hidePadding={currentView === ProfileModal.AvatarSelectionView}
              portalId="modal-scroll-container"
              id="modal-scroll-container"
              {...(currentView === ProfileModal.AvatarSelectionView && {
                className: styles['ProfileModalSelectorMyCanal__profilesModal--no-scrollBar'],
              })}
            >
              <Layer layer={LAYER_SETTINGS_MANAGE_PROFILES}>
                <ProfilesViewHandler from="root" setOpenModal={setShowProfilesViewHandlerModal} />
              </Layer>
            </ModalV2>
          </VirtualKeyboardProvider>
        )}

        {showMaxProfilesReachedModal && (
          <ModalV2 isTvDevice={isTvDevice} e2e="manageProfilesModal" size="medium">
            <LoadableMaxProfilesReachedModal setOpenMaxProfilesReachedModal={setShowMaxProfilesReachedModal} />
          </ModalV2>
        )}
      </>
    );
  };

  return {
    handleProfileClick,
    handleProfileClickWithAuth,
    handleAddProfileClick,
    handleEditButton,
    renderModals,
    closeProfilesModal: () => {
      setShowProfilesViewHandlerModal(false);
    },
  };
};
