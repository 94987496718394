import classNames from 'classnames';
import { forwardRef } from 'react';
import IconClose from '../../assets/svg/close.svg';
import I18n from '../../lang';
import type { UserNotification } from '../../store/slices/user-type';
import Linker from '../Linker/Linker';
import styles from './Notification.css';

export type NotificationProps = UserNotification & {
  closeAction: () => void;
};

const Notification = forwardRef<HTMLDivElement, NotificationProps>(
  ({ type, description, buttonLabel, onClick, closeAction }: NotificationProps, ref) => {
    const { t } = I18n.useTranslation();

    return (
      <div ref={ref} className={classNames(styles.notification, styles[`notification--${type}`])} role="alert">
        <button
          onClick={closeAction}
          className={styles.notification__close}
          type="button"
          data-e2e="notificationBanner_close"
          aria-label={t('Icon.close')}
        >
          <IconClose title={t('Icon.close')} />
        </button>
        <span className={styles.notification__description}>{description}</span>
        {buttonLabel && onClick && (
          <Linker data={{ mainOnClick: onClick }} className={styles.notification__link}>
            <span>{buttonLabel}</span>
          </Linker>
        )}
      </div>
    );
  }
);

Notification.displayName = 'Notification';

export default Notification;
