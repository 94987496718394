import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { getPublicConfig } from '../../../helpers/config/config-helper';
import { loadTrackingScripts } from '../../../helpers/tracking/tracking-helper';
import {
  appKeySelector,
  getFeatureToggleDidomi,
  offerLocationSelector,
  trackingLibraryUrlSelector,
} from '../../../store/slices/application-selectors';
import { displayTVModeSelector, displayWebviewModeSelector } from '../../../store/slices/displayMode-selectors';

/**
 * Load tracking scripts of the application
 *
 * ⚠️ This action must be placed before renderSource action\
 * because we need to load tracking script before client to have sendPageView
 */
export const useLoadTrackingScripts = (): boolean => {
  const [isLoaded, setIsLoaded] = useReducer(() => true, false);
  const publicConfig = getPublicConfig();

  const offerLocation = useSelector(offerLocationSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const isWebview = useSelector(displayWebviewModeSelector);
  const trackingLibraryUrl = useSelector(trackingLibraryUrlSelector);
  const isFeatDidomi = useSelector(getFeatureToggleDidomi);
  const appKey = useSelector(appKeySelector);
  // Check if Didomi is active in config or feature toggle to show Legacy or Didomi consent banner
  const isDidomi = publicConfig.DIDOMI?.ACTIVE || isFeatDidomi;

  useEffect(() => {
    loadTrackingScripts(appKey, offerLocation, isTvDevice || isWebview || isDidomi, trackingLibraryUrl, isTvDevice);
    setIsLoaded();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isLoaded;
};
