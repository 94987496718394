import { memo, ReactNode, useEffect, useRef, useState, CSSProperties } from 'react';
import { executeOnNextFrame } from '@canalplus/mycanal-commons';
import styles from './SlideDown.css';
import { DELAY_TO_DISPLAY_SLIDEDOWN, DELAY_TO_HIDE_SLIDEDOWN } from '../../constants/slidedown';

export type SlideDownProps = {
  children: ReactNode | null;
  isOpen: boolean;
};

function SlideDown({ children, isOpen }: SlideDownProps): JSX.Element {
  const [delayedIsOpen, setDelayedIsOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen !== delayedIsOpen) {
      executeOnNextFrame(
        () => {
          setDelayedIsOpen(isOpen);
        },
        isOpen ? DELAY_TO_DISPLAY_SLIDEDOWN : DELAY_TO_HIDE_SLIDEDOWN
      );
    }
  }, [isOpen, delayedIsOpen]);

  const style = {
    maxHeight: (delayedIsOpen && containerRef.current?.scrollHeight) || 0,
    visibility: (delayedIsOpen ? 'visible' : 'hidden') as CSSProperties['visibility'],
  };

  return (
    <div ref={containerRef} aria-hidden={!delayedIsOpen} className={styles.slideDown} style={style}>
      {children}
    </div>
  );
}

export default memo(SlideDown);
