import { HeaderUserDropdownList } from '@canalplus/dive';
import { ContentType } from '@canalplus/sdk-hodor';
import { getHeaderProfileListItems } from '../../../helpers/navigation/navigation-helper';
import { useUserMenuExtended } from '../hooks/useUserMenuExtended';
import HeaderProfileButtonStrate from './HeaderProfileButtonStrate';

function HeaderProfileContent(): JSX.Element {
  const { userMenuExtendedNonProfileStrates: userMenuStrates } = useUserMenuExtended();

  return (
    <>
      {userMenuStrates.map((strate, index) => {
        switch (strate.type) {
          case ContentType.Sections: {
            const listItems = getHeaderProfileListItems(strate.contents);
            return <HeaderUserDropdownList key={`headerProfile-strate-${strate.type}`} items={listItems} />;
          }

          case ContentType.Button:
            return (
              <HeaderProfileButtonStrate
                key={`headerProfile-strate-${strate.type}`}
                title={strate.title}
                subtitle={strate.subtitle}
                contents={strate.contents}
                isLastStrate={index === userMenuStrates.length - 1}
              />
            );

          default:
            return null;
        }
      })}
    </>
  );
}

export default HeaderProfileContent;
