import { fillTemplate } from '@canalplus/mycanal-commons';
import { AppKey } from '../../../config/application/types';
import { getPublicConfig } from '../config/config-helper';

type GetAlertingV2UrlParameters = {
  path: string;
  appKey: AppKey;
  cmsToken: string;
};

export const getAlertingV2Url = ({ path, appKey, cmsToken }: GetAlertingV2UrlParameters): string => {
  const {
    hodor: { baseUrl },
  } = getPublicConfig().api;

  return fillTemplate(`${baseUrl}/${path}`, [
    ['appKey', appKey],
    ['cmsToken', cmsToken],
  ]);
};
