import { Template } from '@canalplus/sdk-hodor';
import { DAY_IN_MS, YEAR_IN_MS } from '../../constants/date';
import { JsonLocalStorage } from '../jsonLocalStorage/jsonLocalStorage-helper';

const NotificationStorageKey = { Alert: 'alertNextDateDisplay', SlideShow: 'slideshowNextDateDisplay' } as const;

export const displayTemplateStorageKey = {
  notifications: NotificationStorageKey.Alert, // @todo replace this when hodor types 'Template' will be updated with 'Template.Notifications'
  [Template.Slideshow]: NotificationStorageKey.SlideShow,
} as const;

type NotificationParams = {
  accountId: number;
  displayTemplate: string;
  appNextStartup?: boolean;
  contentID?: string;
  daysBetweenTriggers?: number;
};
/**
 * Check if the notification respect theses conditions :
 * - appNextStartup is true
 * - displayTemplate is a Notification template
 * - daysBetweenTriggers === 0 (display each time)
 * - if a nextDateDisplaySlideshow is not present in local storage
 * - if a nextDateDisplaySlideshow is present in local storage, the current date time will be superior or equal of it
 * @param {string} contentID the notification ID
 * @param {number} accountId the account ID
 * @param {boolean} appNextStartup if the notification should be displayed at the next startup
 * @param {number} daysBetweenTriggers the number of days between each triggers
 * @param {string} displayTemplate the display template
 */
export const shouldShowNotification = ({
  contentID,
  accountId,
  appNextStartup,
  daysBetweenTriggers,
  displayTemplate,
}: NotificationParams): boolean => {
  const storageKey = displayTemplateStorageKey[displayTemplate];
  if (!storageKey || !appNextStartup) {
    return false;
  }
  // Get from local storage the next date the notification have to be displayed
  const displayDates: Record<string, number> = JsonLocalStorage.get(`${storageKey}${accountId}`) || {};
  const nextDateDisplay = contentID ? displayDates[contentID] : undefined;

  return daysBetweenTriggers === 0 || !nextDateDisplay || nextDateDisplay <= Date.now();
};

/**
 * Remove the expired notifications entries
 * @param {object} dates Record<string, number>
 * @param {dateTime} dateTime in ms
 * @returns object Record<string, number> filtered
 */
export const removeExpiredNotificationDates = (
  notificationDates: Record<string, number>,
  dateTime: number = Date.now()
): Record<string, number> => {
  const newNotificationDates: Record<string, number> = {};

  Object.keys(notificationDates).forEach((key) => {
    if (notificationDates[key] > dateTime) {
      newNotificationDates[key] = notificationDates[key];
    }
  });

  return newNotificationDates;
};

/**
 * Save in local storage the notification next display date (current date + daysBetweenTriggers)
 * @param {string} contentID the notification ID
 */
export const saveNotificationNextDisplayDate = ({
  contentID,
  accountId,
  daysBetweenTriggers,
  displayTemplate,
}: Omit<NotificationParams, 'appNextStartup'>): void => {
  const storageKey = displayTemplateStorageKey[displayTemplate];

  if (!storageKey || daysBetweenTriggers === 0 || !contentID) {
    return;
  }

  const notificationDates: Record<string, number> = JsonLocalStorage.get(`${storageKey}${accountId}`) || {};

  // if daysBetweenTriggers is undefined, we have to display 1 time the notification. Set the next date at the next year
  const now = Date.now();
  if (daysBetweenTriggers) {
    notificationDates[contentID] = now + daysBetweenTriggers * DAY_IN_MS;
  } else {
    notificationDates[contentID] = now + YEAR_IN_MS;
  }

  JsonLocalStorage.set(`${storageKey}${accountId}`, removeExpiredNotificationDates(notificationDates, now));
};
